import { ThreeCircles } from "react-loader-spinner"
import ProgressBar from "./ProgressBar"
import { useContext, useEffect, useState } from "react"
import { LogUpdateContext } from "src/pages/goodpoint/exam/LogUpdateContext";
import Divider from "src/components/layout/Divider";
import { Check, Page, WarningTriangle } from "iconoir-react";
import { useHasRole } from "src/api/useHasRole";
import { LogType } from "src/api/goodpoint/Const";
import dayjs from "dayjs";


export default function LogProgress({ text }) {
    const isAdmin = useHasRole("admin");
    const { lastLog } = useContext(LogUpdateContext);

    const isSuccess = (lastLog && (lastLog["log_type"] === LogType.SUCCESS));
    const isError = (lastLog && (lastLog["log_type"] === LogType.ERROR));
    const progress = lastLog ? (lastLog["progress"]) : 0.0;
    const logTime = lastLog ? (lastLog["time"] && dayjs(lastLog["time"])) : dayjs();
    const [minsDiff, setMinsDiff] = useState((logTime && dayjs().diff(logTime, "minute")) ?? 0);

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setMinsDiff(dayjs().diff(logTime, "minute"));
        }, 1000);
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [logTime]);

    return <div className="flex flex-col w-full mt-20 items-center justify-center gap-10">
            {
                !isError
                    ? <ThreeCircles
                        visible={true}
                        height="200"
                        width="200"
                        middleCircleColor="var(--zanista-orange-dark)"
                        innerCircleColor="var(--zanista-orange)"
                        outerCircleColor="var(--zanista-orange-mid)"
                    />
                    : <WarningTriangle height={200} width={200} color="var(--zanista-red)"/>
            }
            <div className="w-full flex flex-col gap-3">
                <b>{text}</b>
                <ProgressBar 
                    progress={
                        Math.min(1.0, Math.max(
                            isSuccess ? 1.0 : 0.0, 
                            progress && ((0 + progress["current_step"]) / progress["steps_count"])
                        ))
                    }
                    colour={isError ? "red" : "orange-mid"}
                />
                <div className="flex flex-row justify-between">
                    <p>
                        {
                            progress && (progress["current_step"] < progress["steps_count"]) && 
                            `Step ${progress["current_step"] + 1}/${progress["steps_count"]}: `
                        }
                        {lastLog && lastLog["message"]}
                    </p>
                    <p className="whitespace-nowrap ml-10">
                        {minsDiff < 1 ? "<" : ""}{Math.max(1, minsDiff)} minute{minsDiff > 1 ? "s" : ""} ago
                    </p>
                </div>
                {
                    !isError || !isAdmin
                        ? null
                        : <div className="flex-col w-full gap-mid">
                            <Divider/>
                            <div className="flex flex-row justify-between align-center">
                                <b>Error (shown only to admins)</b><br/>
                                <button 
                                    className=
                                    "flex gap-1 align-center btn-clickable bg-red border-thin rounded-small px-2 py-1"
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(lastLog.error)
                                            .then(() => setCopied(true))
                                    }}
                                    onMouseEnter={() => setCopied(false)}
                                    onBlur={() => setCopied(false)}
                                >
                                    {copied ? <Check width={20} height={20}/> : <Page width={20} height={20}/>}
                                    <p>{copied ? "Copied" : "Copy"}</p>
                                </button>
                            </div>
                            <code className="whitespace-pre text-wrap">{lastLog.error}</code>
                        </div>
                }
            </div>
    </div>
}