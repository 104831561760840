import { useContext, useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import { Tab } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { useFetchClassFeedback } from "src/api/goodpoint/useFetchFeedback";
import useCallApi from "src/api/useCallApi";
import Divider from "src/components/layout/Divider";
import DownloadButton from "src/components/ui/DownloadButton";
import ResetTabButton from "src/pages/goodpoint/exam/components/ResetTabButton";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import ClassGradingContext from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGradingContext";
import ClassStats from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassStats";
import QuestionFeedback from "src/components/content/exam/grading-structure/question-feedback/QuestionFeedback";
import useFetchRubricQuestions from "src/api/goodpoint/useFetchRubric";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


function DownloadOverallFeedback({ ...props }) {
    const { examObject } = useContext(ExamContext);
    const examId = examObject.id;
    const examName = examObject.name;
    const filename = `${slugify(examName, { lower: true, strict: true, replacement: "_" })}_feedback_overall.pdf`;
    return (
        <DownloadButton
            downloadEndpoint={GoodPointApi.Grading.Feedback.Class.Download(examId)}
            filename={filename}
            className="float-right"
            text={`Download Overall Feedback`}
            {...props}
        />
    )
}

export default function ClassGrading() {
    const { examObject } = useContext(ExamContext);
    const fetchRubric = useFetchRubricQuestions(examObject.id);
    const callApi = useCallApi();

    const fetchClassFeedback = useFetchClassFeedback(examObject.id);

    const [fetchedData, setFetchedData] = useState(null);
    const [classFeedback, setClassFeedback] = useState(null);
    const [questionFeedback, setQuestionFeedback] = useState(null);
    const [studentsPerformance, setStudentsPerformance] = useState(null);

    const rubric = useMemo(() => {
        let fetchedRubric = fetchRubric();
        if (fetchedRubric) {
            if (fetchedRubric["rubric"]) {
                const newRubric = fetchedRubric["rubric"].sort((q1, q2) => q1["index"] - q2["index"]);
                return newRubric;
            }
        }
    }, [fetchRubric]);
    const [rubricUrl, setRubricUrl] = useState(null);

    useEffect(() => {
        if (fetchedData) return;
        const result = fetchClassFeedback();
        if (result) {
            setFetchedData(result);
        }
    }, [fetchClassFeedback, fetchedData]);

    useEffect(() => {
        if (classFeedback || !fetchedData) return;
        const cf = fetchedData["class_feedback"];
        setClassFeedback(cf);
    }, [classFeedback, fetchedData]);

    useEffect(() => {
        if (questionFeedback || !fetchedData) return;
        setQuestionFeedback(fetchedData["question_feedback"]);
    }, [fetchedData, questionFeedback]);

    useEffect(() => {
        if (studentsPerformance || !fetchedData) return;
        setStudentsPerformance(fetchedData["students_performance"]);
    }, [fetchedData, studentsPerformance]);

    useEffect(() => {
        if (rubricUrl) return;
        callApi("GET", GoodPointApi.Rubric.Link(examObject.id))
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        var url = body["data"]["rubric_url"];
                        setRubricUrl(url);
                    })
                }
            })
    }, [callApi, examObject.id, rubricUrl]);

    return (
        <div className="w-full h-full space-y-4">
            <ClassGradingContext.Provider value={{
                classFeedback, 
                setClassFeedback,
                questionFeedback, 
                studentsPerformance,
            }}>
                <ClassStats />
                <RubricContext.Provider value={{
                    rubric, setRubric: () => {}, rubricUrl, setRubricUrl, editMode: null, setEditMode: () => {}, 
                    isEnhanced: false, waitingForMarksUpdate: false, setWaitingForMarksUpdate: () => {}
                }}>
                    <div className="question-feedback-section py-4">
                        <h2 className="text-lg"><b>Question Feedback</b></h2>
                        <Divider/>
                        <p className="text-sm">
                            Feedback for each question is aggregated from the performance of all the students in the class.
                            To view a single student's feedback, click on the "Student Performance" button above.
                        </p>
                        <Divider className="mt-2"/>
                        <QuestionFeedback />
                    </div>
                </RubricContext.Provider>
                <div className="flex flex-row justify-between">
                    <DownloadOverallFeedback />
                    <ResetTabButton tab={Tab.GRADING} />
                </div>
            </ClassGradingContext.Provider>
        </div>
    )
}