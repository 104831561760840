import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useDeleteTicker() {
    const callApi = useNewsWitchApi();

    const deleteTicker = useCallback((ticker) => {
        return callApi("DELETE", "/ticker?" + new URLSearchParams({
            ticker: ticker
        }).toString())?.then(response => {
            if (response.status === 200) {
                return {success: true, message: "Ok"};
            }
            // Linked ticker exists
            else if (response.status === 409) {
                return {success: false, message: "Another ticker links to this ticker. Please delete the linked ticker first."};
            }
            // Other error
            else {
                console.error(response);
                return {success: false, message: "An error occurred while deleting the ticker."};
            }
        });
    }, [callApi]);

    return deleteTicker;
}
