import { ExamStructure } from "src/components/content/exam/ExamStructure";
import "./AnswersStructure.css"
import { useContext } from "react";
import LoadingTab from "src/components/ui/LoadingTab";
import { useHasRole } from "src/api/useHasRole";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import useSendEdit from "src/api/goodpoint/useSendEdit";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import AnswerItem from "src/components/content/exam/answers-structure/AnswerItem";
import useCallApi from "src/api/useCallApi";


export default function AnswersStructure({ selectedStudent, answers }) {
    const callApi = useCallApi();
    const { examObject } = useContext(ExamContext);
    const { answerPdfUrl, setStudents, setSelectedStudent } = useContext(StudentsContext);
    const studentConfig = selectedStudent["config"];
    const { student_name: studentName } = studentConfig;
    const { sendEdit, isSending } = 
        useSendEdit(GoodPointApi.Answers.Student.Edit(examObject.id, selectedStudent.id));

    const handleNameChange = (event) => {
        const newName = event.target.value;
        callApi("POST", GoodPointApi.Answers.Student.Config(examObject.id, selectedStudent.id), { body: {
            config: {"student_name": newName}
        }})
            ?.then(response => {
                if (response.ok) {
                    setStudents(prevState => {
                        const updatedStudents = { ...prevState };
                        updatedStudents[selectedStudent.id]["config"]["student_name"] = newName;
                        return updatedStudents;
                    });
                    setSelectedStudent((ss) => { 
                        const newConfig = { ...ss["config"], "student_name": newName };
                        return {...ss, "config": newConfig}
                    });
                }
            });
    }

    const readOnly = useHasRole("student");

    function AnswersHeader({ structure }) {
        const numQuestions = structure.length;
        return (
            <div className="grid grid-cols-3 gap-10 align-center">
                <div className="flex-row align-center gap-2">
                    <p>Name:</p>
                    {
                        !readOnly
                        ? <input
                            className={
                                `border-2 border-grey-500 rounded-lg px-2 focus:outline-black ` + 
                                `hover:border-2 hover:border-[var(--zanista-orange-dark)]`
                            }
                            defaultValue={studentName}
                            onBlur={handleNameChange}
                            disabled={readOnly}
                        />
                        : <b>{studentName}</b>
                    }
                </div>
                <div><b>ID:</b> {selectedStudent["id"]}</div>
                <div className=" justify-self-end text-right">
                    <p>{numQuestions} Question{(numQuestions > 1) ? "s" : ""} Answered</p>
                </div>
            </div>
        )
    }

    if (!answers) {
        return <LoadingTab />
    }

    return (
        <ExamStructure
            structureInit={answers}
            saveEdit={sendEdit}
            isSavingEdit={isSending}
            HeaderComponent={AnswersHeader}
            ItemComponent={AnswerItem}
            fileUrl={answerPdfUrl}
            listDisplay={[0, 0, 1, 1, 1, 0]}
        />
    )
}