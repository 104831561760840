import { useMemo, useContext, useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import { useHasRole } from "src/api/useHasRole";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import StructureSection from "src/components/content/exam/StructureSection";
import { StructureItem } from "src/components/content/exam/StructureItem";
import RubricSteps from "src/components/content/exam/rubric-structure/RubricSteps";
import { isAnyFlagged } from "src/components/content/exam/StructureUtil";
import { EditAction } from "src/api/goodpoint/Const";
import { TailSpin } from "react-loader-spinner";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";


function RubricItemHeader({ itemData, indexHierarchy, nestingLevel }) {
    const [warningOpen, setWarningOpen] = useState(false);
    const { saveEdit, editStructure } = useContext(ExamStructureContext);
    const { waitingForMarksUpdate, setWaitingForMarksUpdate } = useContext(RubricContext);

    const flagged = useMemo(() => isAnyFlagged(itemData), [itemData]);
    const numParts = useMemo(() => {
        const subQuestions = itemData.sub_items;
        return (subQuestions ?? []).length;
    }, [itemData.sub_items]);
    const marksInputRef = useRef(null);

    useEffect(() => {
        if (!waitingForMarksUpdate) {
            marksInputRef.current.value = itemData.max_marks;
        }
    }, [itemData.max_marks, waitingForMarksUpdate]);

    const handleMarkChange = useCallback((event) => {
        const newMark = parseFloat(event.target.value);
        if (newMark === itemData.max_marks) return;
        if (warningOpen) {
            event.target.value = itemData.max_marks;
            setWarningOpen(false);
            return;
        }
        setWaitingForMarksUpdate(true);
        const editAction = {
            "action": EditAction.MARKS, "indexes": [...indexHierarchy, itemData.index], "value": newMark
        };
        // Sending a mark edit to the backend will return the newly updated structure item including its sub items
        // Therefore, we must update the structure with this new item
        saveEdit([editAction], (result) => {
            editStructure({
                "action": EditAction.REPLACE,
                "indexes": (indexHierarchy.length === 0) ? [itemData.index] : indexHierarchy, 
                "item": result
            });
            setWaitingForMarksUpdate(false);
        });
    }, [editStructure, indexHierarchy, itemData.index, itemData.max_marks, saveEdit, setWaitingForMarksUpdate, warningOpen]);

    const readOnly = useHasRole("student");

    return (
        <div className="w-full">
            <div className="flex-row flex justify-between">
                <p className=""><b>{nestingLevel > 0 ? "Part " : ""} {itemData.name}</b></p>
                {
                    flagged && <p className="text-right text-zanista-red">(NEEDS REVIEW)</p>
                }
                {numParts > 0 && (<p className="">{numParts} Parts</p>)}
                <Tooltip
                    title={
                        readOnly ? "" :
                            itemData.hierarchy.length === 0
                                ? "Adjusts all related subquestion marks."
                                : (itemData.sub_items || []).length === 0
                                    ? "Affects parent question's total marks."
                                    : "Impacts both subquestions and parent marks."
                    }
                    placement="right"
                >
                    <div className="justify-self-right">
                        {
                            readOnly
                                ? <p>{itemData?.max_marks} Marks</p>
                                : <div className="flex flex-row gap-2 items-center">
                                    {
                                        waitingForMarksUpdate &&
                                        <TailSpin width="1em" height="1em" color="black" strokeWidth={3.0} />
                                    }
                                    <Tooltip
                                        open={warningOpen}
                                        onOpen={() => setWarningOpen(true)}
                                        onClose={() => setWarningOpen(false)}
                                        title="Invalid marks"
                                        disableHoverListener={true}
                                        disableFocusListener={true}
                                    >
                                        <input
                                            ref={marksInputRef}
                                            type="number"
                                            // stops item from expanding on click
                                            onClick={(event) => event.stopPropagation()}
                                            onBlur={handleMarkChange}
                                            onChange={(event) => {
                                                const newMarks = parseFloat(event.target.value);
                                                setWarningOpen(newMarks < 0.0);
                                            }}
                                            defaultValue={itemData.max_marks}
                                            className={
                                                `w-12 ${
                                                    waitingForMarksUpdate ? "bg-slate-300" : (
                                                        warningOpen ? "bg-zanista-red-light" : "bg-white"
                                                    )
                                                } ` + 
                                                `focus:outline-accent text-right rounded-md`
                                            }
                                            disabled={readOnly || waitingForMarksUpdate}
                                        />
                                    </Tooltip>
                                    Marks
                                </div>
                        }
                    </div>
                </Tooltip>
            </div>
        </div>
    );
}

export function RubricItemContent({ itemData, indexHierarchy, editKeysHierarchy }) {
    const { itemCommonProps } = useContext(ExamStructureContext);
    const isEnhanced = itemCommonProps.isEnhanced;

    const { question_content: questionText, description, result, solution_content: solutionText} = itemData;

    return (
        <div className="rubric-item-content">
            {questionText && (
                <StructureSection
                    heading={"Question Content"}
                    content={questionText}
                    editKeys={[...editKeysHierarchy, "question_content"]}
                />
            )}
            {isEnhanced && description && (
                <StructureSection
                    heading={"Description"}
                    content={description}
                    editKeys={[...editKeysHierarchy, "description"]}
                    aiGenerated
                />
            )}
            {isEnhanced && result && (
                <StructureSection
                    heading={"Result"}
                    content={result}
                    editKeys={[...editKeysHierarchy, "result"]}
                    aiGenerated
                />
            )}
            {solutionText && (
                <StructureSection
                    heading={"Solution Content"}
                    content={solutionText}
                    editKeys={[...editKeysHierarchy, "solution_content"]}
                />
            )}
            {isEnhanced && itemData.steps && (
                <RubricSteps 
                    steps={itemData.steps} 
                    editKeysHierarchy={[...editKeysHierarchy, "steps"]} 
                    indexHierarchy={[...indexHierarchy, itemData.index]}
                />
            )}
        </div>
    );
}

export function RubricItem(props) {
    const {
        editMode,
        setEditMode,
    } = useContext(ExamContext);

    return (
        <StructureItem
            HeaderComponent={RubricItemHeader}
            ContentComponent={RubricItemContent}
            editMode={editMode}
            setEditMode={setEditMode}
            {...props}
        />
    );
}

export function EnhancedRubricItem({ itemData, nestingLevel = 0 }) {
    return (
        <RubricItem
            itemData={itemData}
            enhanced={true}
            nestingLevel={nestingLevel}
        />
    );
}
