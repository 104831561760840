import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FocusWithin } from "@component-driven/react-focus-within";

import "./GradeBoundaries.css";
import { Check, ListSelect, PlusCircle, Xmark, XmarkCircle } from "iconoir-react";
import { useHasRole } from "src/api/useHasRole";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import Popup from "reactjs-popup";


export function calcGrade(percentage, gradeBoundaries) {
    if (percentage === null || percentage === undefined) return undefined;
    if (!gradeBoundaries) return undefined;
    const gb = Object
        .entries(gradeBoundaries)
        .sort(([_g1, b1], [_g2, b2]) => b2 - b1);
    for (let [g, b] of gb) {
        if (percentage >= b) {
            return g;
        }
    }
    const [g,] = gb[0];
    return g;
}

function BoundaryRow({ index, gradeBoundariesList, setGradeBoundariesList, setNumErrors }) {
    const disabled = useHasRole("student");

    const grade = gradeBoundariesList[index][0];
    const mark = gradeBoundariesList[index][1];

    const gradeInput = useRef(null);
    const markInput = useRef(null);

    const [badGrade, setBadGrade] = useState(false);
    const [badMark, setBadMark] = useState(false);

    const onChangeGrade = useCallback((event) => {
        const newGrade = event.target.value;
        const result = [...gradeBoundariesList];
        result[index] = [newGrade, mark];
        const bg = (newGrade.length === 0) || (result.filter(([g, _m]) => g === newGrade).length > 1);
        if (badGrade && !bg) {
            setBadGrade(false);
            setNumErrors(ne => ne - 1);
        } else if (!badGrade && bg) {
            setBadGrade(true);
            setNumErrors(ne => ne + 1);
        }
        setGradeBoundariesList(result);
    }, [badGrade, gradeBoundariesList, index, mark, setGradeBoundariesList, setNumErrors]);

    const onChangeMark = useCallback((event) => {
        const newMark = parseInt((event.target.value ?? `${mark}`).replace(/\D/g, ""));
        if (newMark <= 100) {
            const result = [...gradeBoundariesList];
            result[index] = [grade, newMark];
            const bm = (result.filter(([_g, m]) => m === newMark).length > 1);
            if (badMark && !bm) {
                setBadMark(false);
                setNumErrors(ne => ne - 1);
            } else if (!badMark && bm) {
                setBadMark(true);
                setNumErrors(ne => ne + 1);
            }
            setGradeBoundariesList(result);
        }
    }, [badMark, grade, gradeBoundariesList, index, mark, setGradeBoundariesList, setNumErrors]);

    function deleteSelf() {
        const result = [...gradeBoundariesList];
        result.splice(index, 1);
        setGradeBoundariesList(result);
    }

    useEffect(() => {
        if ((grade === undefined) && gradeInput) {
            gradeInput.current.focus();
        }
    }, [grade, gradeInput]);

    const enableDelete = gradeBoundariesList.filter(([g, m]) => (g !== undefined) && (m !== undefined)).length > 1;

    return (
        <tr className="boundary-row align-center justify-content-center">
            <td
                className={`input-cell cursor-text ${badGrade ? "bg-red-mid" : "null"}`}
                onClick={() => { gradeInput.current.focus(); }}
            >
                <input
                    ref={gradeInput}
                    className="grade-input border-none margin-none outline-none"
                    type="text"
                    value={grade}
                    placeholder="Grade"
                    onChange={onChangeGrade}
                    disabled={disabled}
                />
            </td>
            <td
                className={
                    `input-cell flex-row align-center cursor-text fill-width ${badMark ? "bg-red-mid" : null}`
                }
                onClick={() => { markInput.current.focus(); }}
            >
                <input
                    ref={markInput}
                    className="mark-input border-none margin-none outline-none"
                    type="text"
                    value={mark}
                    placeholder="Mark"
                    onChange={onChangeMark}
                    disabled={disabled}
                />
                {
                    (mark !== undefined) && <p>%</p>
                }
            </td>
            <td className="align-center p-0 m-0 justify-content-center">
                {!disabled &&
                    <button
                        className={`delete-grade-row padding-none margin-none align-center`}
                        onClick={deleteSelf}
                        disabled={!enableDelete || disabled}
                    >
                        <XmarkCircle height={"1.3em"} style={{ opacity: enableDelete ? 1 : 0 }} />
                    </button>
                }
            </td>
        </tr>
    )
}

export default function GradeBoundaries({ gradeBoundaries, setGradeBoundaries }) {
    const [gradeBoundariesList, setGradeBoundariesList]
        = useState(
            Object
                .entries(gradeBoundaries)
                .sort(([_g1, m1], [_g2, m2]) => m2 - m1)
        );

    const [toUpdate, setToUpdate] = useState(false);
    const [numErrors, setNumErrors] = useState(0);

    const onClickSave = useCallback(() => {
        var gb = gradeBoundariesList.filter(([g, m]) => (g !== undefined) && (m !== undefined));
        gb = Array.from(new Set(gb.map(([_g, m]) => m)));
        gb = gb.map((mF) => gradeBoundariesList.find(([_g, m]) => m === mF));
        if (gb.find(([_g, m]) => m === 0) === undefined) {
            gb.push(["Fail", 0]);
        }
        const gbObj = Object.fromEntries(gb);
        setGradeBoundaries(gbObj);
        setGradeBoundariesList(
            Object
                .entries(gbObj)
                .sort(([_g1, m1], [_g2, m2]) => m2 - m1)
        );
        setToUpdate(false);
    }, [gradeBoundariesList, setGradeBoundaries]);

    function setGradeBoundariesListSorted(newGb) {
        setGradeBoundariesList(newGb);
        setToUpdate(true);
    }

    function addRow() {
        if (gradeBoundariesList.filter(([g, m]) => (g === undefined) || (m === undefined)).length === 0) {
            setGradeBoundariesList([...gradeBoundariesList, [undefined, undefined]]);
        }
    }

    function onBlur() {
        setGradeBoundariesList(gb => gb.filter(([g, m]) => (g !== undefined) && (m !== undefined)));
    }

    const disabled = useHasRole("student");

    return (
        <div className="py-4 pt-2">
            <FocusWithin onBlur={onBlur}>
                <table className='grade-grid rounded-md border-transparent outline-transparent'>
                    <thead>
                        <tr className="padding-large">
                            <td><p className="margin-none text-align-center">Grade</p></td>
                            <td><p className="margin-none text-align-center">Min. Mark (%)</p></td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gradeBoundariesList
                                .map((_, index) => (
                                    <BoundaryRow
                                        key={index}
                                        index={index}
                                        gradeBoundariesList={gradeBoundariesList}
                                        setGradeBoundariesList={setGradeBoundariesListSorted}
                                        setNumErrors={setNumErrors}
                                    />
                                ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={2}>
                                <div className="flex-row gap-small fill-width">
                                    {
                                        !disabled &&
                                        <button 
                                            className="add-grade flex-row gap-small align-center fill-width" 
                                            onClick={addRow}
                                        >
                                            <PlusCircle height={"1.3em"} />Add grade
                                        </button>
                                    }
                                    {
                                        toUpdate && 
                                        <button
                                            className={
                                                `flex flex-row gap-1 rounded-md p-2 pl-3 ` +
                                                `items-center border border-grey border-1 ` +
                                                `${
                                                    (numErrors <= 0) 
                                                    ? "bg-zanista-yellow-light hover:bg-zanista-orange-mid"
                                                    : "bg-slate-300 text-gray-500 pointer-events-none"
                                                }`
                                            }
                                            onClick={onClickSave}
                                        >
                                            <p>Save</p>
                                            {
                                                (numErrors <= 0)
                                                ? <Check width="1.3em" strokeWidth={2.5}/>
                                                : <Xmark width="1.3em" strokeWidth={2.5}/>
                                            }
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </FocusWithin>
        </div>
    )
}

export function GradeBoundariesPopup({ popupOpen, setPopupOpen }) {
    const { gradeBoundaries, setGradeBoundaries } = useContext(GradingContext);
    return (
        <Popup open={popupOpen} closeOnDocumentClick={true} onClose={() => setPopupOpen(false)}>
            {
                gradeBoundaries &&
                <div className='bg-white rounded-small w-full h-full'>
                    <GradeBoundaries gradeBoundaries={gradeBoundaries} setGradeBoundaries={setGradeBoundaries} />
                </div>
            }
        </Popup>
    )
}

export function EditGradeBoundaries() {
    const [popupOpen, setPopupOpen] = useState(false);
    return <>
        <button className=
            "flex flex-row gap-2 p-2 px-3 btn-clickable rounded-md bg-zanista-orange-light hover:scale-105"
            onClick={() => setPopupOpen(true)}
        >
            <ListSelect /> <p>Edit Grade Boundaries</p>
        </button>
        <GradeBoundariesPopup popupOpen={popupOpen} setPopupOpen={setPopupOpen} />
    </>
}