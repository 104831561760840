import { Skeleton, Stack } from "@mui/material";
import { ArrowRight, DocMagnifyingGlass, Download, FastArrowDown, FastArrowRight, NavArrowDown, NavArrowUp, TrashSolid, Upload, Xmark } from "iconoir-react";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { RotatingSquare, TailSpin } from "react-loader-spinner";
import { GoodpointGptModel, GoodpointVisionModel, StepState, Tab } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { useBlobDownload } from "src/api/useBlobDownload";
import { useBlobView } from "src/api/useBlobView";
import useCallApi from "src/api/useCallApi";
import { useFileUpload } from "src/api/useFileUpload";
import Divider from "src/components/layout/Divider";
import ResetTabButton from "src/pages/goodpoint/exam/components/ResetTabButton";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


function ViewOriginalButton({ examId, filename }) {
    const {view, isFetching} = useBlobView(GoodPointApi.Answers.Original.View(examId, filename));
    return (
        <button
            className="
                btn-clickable font-size-medium bg-yellow-light border-thin flex-row align-center 
                gap-small rounded-xsmall px-2 py-0 text-lg
            "
            onClick={view}
        >
            {
                isFetching
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="px-0" />
                    : <DocMagnifyingGlass width={20} />
            }
            <p>View</p>
        </button>
    )
}


function DownloadOriginalButton({ examId, filename }) {
    const { download, isDownloading } = useBlobDownload(
        GoodPointApi.Answers.Original.Download(examId, filename), 
        filename
    );
    return (
        <button
            className="
                btn-clickable font-size-medium bg-yellow-light border-thin flex-row align-center 
                gap-small rounded-xsmall px-2 py-0 text-lg
            "
            onClick={() => download()}
        >
            {
                isDownloading
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="px-0" />
                    : <Download width={20}/>
            }
            <p>Download</p>
        </button>
    );
}


function DeleteUploadButton({ filename, numDeleting, setNumDeleting }) {
    const { examObject } = useContext(ExamContext);
    const callApi = useCallApi();
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteUpload = useCallback((f_name) => {
        setIsDeleting(true);
        setNumDeleting((n) => n + 1);
        callApi("DELETE", GoodPointApi.Answers.Upload.Delete(examObject.id, f_name))
            ?.then((response) => {
                if (response.status === 200) {
                    setNumDeleting((n) => n - 1);
                }
            });
    }, [callApi, examObject.id, setNumDeleting]);

    useEffect(() => {
        if (numDeleting === 0) {
            setIsDeleting(false);
        }
    }, [numDeleting]);

    return (
        isDeleting
            ? <TailSpin strokeWidth={3.0} color="red" width={20} height={20} wrapperClass="padding-small" />
            : <TrashSolid
                onClick={() => deleteUpload(filename)}
                className="
                    h-full p-1 w-7 text-red-500 transition-all duration-200 hover:p-0 cursor-pointer
                "
            />
    )
}


function MiniUploadButton({ examId, setNeedReload, setLoadingComponents }) {
    const { isUploading, upload } = useFileUpload(GoodPointApi.Answers.Upload(examId), "answers");
    const fileRef = useRef(null);

    const onButtonClick = () => {
        if (isUploading) return;
        fileRef.current.click();
    }

    const onChange = () => {
        setLoadingComponents((lc) => { return {...lc, uploadTable: true }});
        upload(fileRef.current.files, () => {setNeedReload(true);});
    }

    return (
        <button 
            className={
                `font-size-medium bg-orange-mid border-thin flex-row align-center ` +
                `gap-small rounded-xsmall p-0 text-lg ` +
                `${isUploading ? "disabled" : "btn-clickable"}`
            }
            onClick={onButtonClick}
        >
            <div className='p-2'>
                {
                    isUploading 
                        ? <RotatingSquare color="black" height={30} width={30} />
                        : <Upload color="black" height={30} width={30} />
                }
            </div>
            <input type='file' ref={fileRef} style={{ display: 'none' }} onChange={onChange} multiple={true} />
        </button>
    )
}


function UploadTable({ setLoadingComponents }) {
    const { examObject, examState, updateExamState } = useContext(ExamContext);

    const callApi = useCallApi();
    const [uploadFiles, setUploadFiles] = useState(null);
    const [needReload, setNeedReload] = useState(true);

    const [numDeleting, setNumDeleting] = useState(0);

    useEffect(() => {
        if (needReload || numDeleting > 0 || !uploadFiles) {
            setLoadingComponents((lc) => { return {...lc, "uploadTable": true} });
        } else {
            setLoadingComponents((lc) => { return {...lc, "uploadTable": false} });
        }
    }, [needReload, numDeleting, setLoadingComponents, uploadFiles]);

    useEffect(() => {
        if (!needReload || numDeleting > 0) return;
        setUploadFiles(null);
        callApi("GET", GoodPointApi.Answers.Upload(examObject.id))
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const uploadedFiles = data["files"];
                            setUploadFiles(uploadedFiles);
                            const newState = data["new_state"];
                            updateExamState(newState);
                        })
                        setNeedReload(false);
                    }
                }
            )
    }, [callApi, examObject.id, examState.answers, needReload, numDeleting, updateExamState]);

    useEffect(() => {
        if (numDeleting > 0 && !needReload) {
            setNeedReload(true);
        }
    }, [needReload, numDeleting]);

    return (
        uploadFiles
            ? <table className="table-auto border-separate border-spacing-2 w-full">
                <tbody>
                    <tr>
                        <td colSpan={5}>
                            <h2 className="text-lg"><b>Upload</b></h2>
                        </td>
                    </tr>
                    {
                        uploadFiles.map((f_name, i) =>
                            <tr key={i}>
                                <td>{i+1}.</td>
                                <td className="w-full"><p>{f_name}</p></td>
                                <td><ViewOriginalButton examId={examObject.id} filename={f_name}/></td>
                                <td><DownloadOriginalButton examId={examObject.id} filename={f_name}/></td>
                                <td className="px-2">
                                    <DeleteUploadButton
                                        filename={f_name}
                                        numDeleting={numDeleting}
                                        setNumDeleting={setNumDeleting}
                                    />
                                </td>
                            </tr>
                        )
                    }
                    <tr>
                        <td colSpan={5} className="align-right text-right">
                            <div className="flex-row gap-mid align-center justify-end mt-2">
                                <p>Upload more (.pdf)</p>
                                <MiniUploadButton 
                                    examId={examObject.id} 
                                    setNeedReload={setNeedReload}
                                    setLoadingComponents={setLoadingComponents}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            : <TableSkeleton/>
    )
}


function ConfigTable({ config, updateConfig, setLoadingComponents }) {
    const [configOpen, setConfigOpen] = useState(true);
    const { examState } = useContext(ExamContext);
    const [advancedOpened, setAdvancedOpened] = useState(false);

    return (
        !(config && examState.answers >= StepState.READY)
            ? <TableSkeleton />
            : (
            <table className="table-auto border-separate border-spacing-2 w-full">
            <tbody>
                <tr className="font-normal p-0 m-0">
                    <td colSpan={2} className="w-full cursor-pointer p-0 m-0" onClick={() => setConfigOpen((c) => !c)}>
                        <div className="flex-row w-full align-center justify-between m-0 p-0 gap-0">
                            <h2 className="text-lg"><b>Options</b></h2>
                            {
                                configOpen
                                    ? <NavArrowDown/>
                                    : <NavArrowUp/>
                            }
                        </div>
                    </td>
                </tr>
                {
                    !configOpen 
                        ? null
                        : <>
                        <tr>
                            <td className="w-1/3"><p>Submission Type</p></td>
                            <td><select
                                className={
                                    `w-full px-[10px] py-[14px] rounded-[4px] border border-zanista-grey-mid ` +
                                    `transition hover:border-black`
                                }
                                defaultValue={config["submission_type"]}
                                onBlur={(e) => updateConfig({"submission_type": e.target.value})}
                            >
                                <option>Handwritten</option>
                                <option>Typed</option>
                            </select></td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                <div 
                                    className=
                                        "mt-2 mb-2 flex-row gap-small text-zanista-orange align-center cursor-pointer"
                                    onClick={() => setAdvancedOpened((a) => !a)}
                                >
                                    {
                                        advancedOpened
                                            ? <FastArrowDown color="var(--zanista-orange-dark)" width={"1em"} />
                                            : <FastArrowRight color="var(--zanista-orange-dark)" width={"1em"} />
                                    }
                                    <b className="user-select-none">Advanced</b>
                                    <div className="w-full ml-[5px] right-0 border-t border-zanista-orange-mid"></div>
                                </div>
                            </td>
                        </tr>
                        {
                            advancedOpened
                                ? <>
                                    <tr>
                                        <td>OCR Method</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                        >
                                            <option value="azure-vision">Azure Vision</option>
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td>GPT Model</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            defaultValue={config["gpt_model"]}
                                            onBlur={(e) => updateConfig({"gpt_model": e.target.value})}
                                        >
                                            {
                                                Object
                                                    .entries(GoodpointGptModel)
                                                    .map(([k, v]) => <option value={k}>{v}</option>)
                                            }
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td>Vision Model</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` +
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            defaultValue={config["vision_model"]}
                                            onBlur={(e) => updateConfig({"vision_model": e.target.value})}
                                        >
                                            {
                                                Object
                                                    .entries(GoodpointVisionModel)
                                                    .map(([k, v]) => <option value={k}>{v}</option>)
                                            }
                                        </select></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                }
            </tbody>
            </table>
        )
    )
}


function TableSkeleton() {
    return (
        <Stack className="mt-1 px-4 w-full">
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
        </Stack>
    )
}


function ProcessButton({ autoRun = false }) {
    const { examObject, examState, updateExamState } = useContext(ExamContext);
    const callApi = useCallApi();
    const [starting, setStarting] = useState(false);
    const enabled = useMemo(
        () => (examState.rubric === StepState.COMPLETED),
        [examState.rubric]
    );

    const onButtonClick = useCallback(() => {
        setStarting(true);
        callApi("POST", GoodPointApi.Answers.Process(examObject.id))
            ?.then((response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        const data = body["data"];
                        const newState = data["state"];
                        updateExamState({ answers: newState });
                    })
                }
            });
    }, [callApi, examObject.id, updateExamState]);

    return (
        <button 
            className={
                `flex flex-row gap-2 p-2 pl-3 rounded-md items-center ${enabled && "border-mid"}` +
                ` ${starting ? "bg-zanista-red" : enabled ? "bg-zanista-orange" : "bg-slate-300"}` +
                ` transition-all duration-300 ease-out` +
                ` ${(!enabled || starting) ? "disabled" : "hover:bg-zanista-red btn-clickable"}` +
                ` ${enabled ? "text-black" : "text-gray-500"}`
            }
            onClick={onButtonClick}
            disabled={!enabled || starting}
        >
            <b>{autoRun ? "Waiting for rubric" : enabled ? "Begin Processing" : "Not available"}</b>
            {
                autoRun
                    ? <TailSpin strokeWidth={3.0} color="gray" width={20} height={20} wrapperClass="padding-small" />
                    :
                !enabled
                    ? <Xmark width={20} height={20} strokeWidth={2.0}/>
                    :
                starting
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="padding-small" />
                    : <ArrowRight width={20} strokeWidth={2.0}/>
            }
        </button>
    )
}


function ConfigAutoRun({ config, updateConfig }) {
    return (
        config &&
        <div className="flex flex-row w-full px-2 gap-2 items-center justify-end text-right">
            <p>Auto run?</p>
            <input 
                className=
                    "mt-2 mb-2 p-5 w-6 h-6 accent-zanista-orange border border-zanista-grey-mid"
                type="checkbox"
                checked={config["auto_run"]}
                onChange={(e) => updateConfig({"auto_run": e.target.checked})}
            ></input>
        </div>
    )
}


export default function AnswersReady() {
    const callApi = useCallApi();
    const { examObject, examState } = useContext(ExamContext);
    const [config, setConfig] = useState(null);
    const { setFooterControls } = useContext(ExamContext);
    const [needReload, setNeedReload] = useState(true);
    const [loadingComponents, setLoadingComponents] = useState({
        uploadTable: true,
        configTable: true
    });
    const autoRunAllowed = useMemo(() => {
        return examState.rubric < StepState.COMPLETED
    }, [examState.rubric]);
    
    useLayoutEffect(() => {
        setFooterControls([
            <ResetTabButton tab={Tab.ANSWERS}/>
        ])
    }, [setFooterControls]);

    useEffect(() => {
        if (!needReload) return;
        setConfig(null);
        callApi("GET", GoodPointApi.Answers.Config(examObject.id))
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const config = data["config"];
                            setConfig(config);
                        })
                        setNeedReload(false);
                        setLoadingComponents((lc) => { return {...lc, configTable: false }});
                    }
                }
            )
    }, [callApi, examObject.id, needReload, setLoadingComponents]);

    const updateConfig = useCallback((updates) => {
        setConfig((c) => {
            const newConfig = {...c, ...updates};
            callApi("POST", GoodPointApi.Answers.Config(examObject.id), { body: {"config": newConfig} });
            return newConfig;
        });
    }, [callApi, examObject.id]);

    return (
        <div className="centered">
            <div className="w-1/2">
                <UploadTable setLoadingComponents={setLoadingComponents} />
                <Divider/>
                <ConfigTable config={config} updateConfig={updateConfig} setLoadingComponents={setLoadingComponents} />
                <Divider/>
                {
                    Object.values(loadingComponents).every(v => v === false)
                        ? <div className="space-y-3">
                            <div className="flex-row mt-4 align-center w-full justify-between px-2">
                                <p>Credits cost: <b>[PLACEHOLDER]</b></p>
                                <ProcessButton autoRun={autoRunAllowed && config?.auto_run}/>
                            </div>
                            {
                                autoRunAllowed &&
                                <ConfigAutoRun config={config} updateConfig={updateConfig}/>
                            }
                        </div>
                        : <Stack className="mt-1 px-4 w-full">
                            <div className="grid grid-cols-2 gap-4 -mt-4">
                                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                            </div>
                        </Stack>
                }
            </div>
        </div>
    );
}