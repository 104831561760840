import React, { useState, useEffect } from 'react';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginRequest, silentRequest } from 'src/auth/AuthConfig';
import useHasAccess from './useHasAccess';
import useGetAccess from './useGetAccess';
import { useCallHasRole } from './useHasRole';

/**
 * Only allows access to a route if the user is authenticated and has the required role(s) and access to the required product(s)
 * The user can have access to ANY of the required products (default) unless productsAll is true, in which case the user must have access to ALL required products
 * The user can have access to ANY of the required products (default) unless productsOnly is true, in which case the user must ONLY have access to the specified products
 * Similarly for roles, although currently a user can only have one role so rolesOnly does not exist
 * If checkAdmin is true (default), admins will be allowed to access any route regardless of the other conditions
 */
export const RouteGuard = ({ products = [], roles = [], productsAll = false, productsOnly = false, rolesAll = false, checkAdmin = true, navigateOnError = true, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(undefined);

    const currentAccount = instance.getActiveAccount();

    const hasAccess = useHasAccess();
    const hasRole = useCallHasRole();
    const getAccess = useGetAccess();

    let authRequest = {
        ...loginRequest,
    };

    // Try a silent login if currentAccount is available
    if (currentAccount && currentAccount.idTokenClaims.emails && currentAccount.idTokenClaims.emails.length > 0) {
        authRequest = {
            ...silentRequest,
            loginHint: currentAccount.idTokenClaims.emails[0],
        }
    }

    useEffect(() => {

        // If there are no restrictions set, allow access
        if (currentAccount && roles.length === 0 && products.length === 0) {
            setIsAuthorized(true);
            return;
        }

        // Forcefully allow admins to access any route if checkAdmin flag is false
        if (!checkAdmin && hasRole("admin")) {
            setIsAuthorized(true);
            return;
        }

        // If roles passed to RouteGuard, check if user has a role that matches
        if (currentAccount && roles.length > 0) {
            const allowed = rolesAll ? roles.every(role => hasRole(role)) : roles.some(role => hasRole(role));
            setIsAuthorized(allowed);
            if (!allowed) {
                return;
            }
        }

        // Check if user has access to products
        if (currentAccount && products.length > 0) {
            // Check if every user's product is in the required products
            if (productsOnly && !getAccess().every(item => products.includes(item))) {
                setIsAuthorized(false);
                return;
            }

            // Check if user has access to correct amount of products
            const allowed = productsAll ? products.every(product => hasAccess(product)) : products.some(product => hasAccess(product));
            setIsAuthorized(allowed);
            return;
        } else if (currentAccount && products.length === 0) {
            setIsAuthorized(true);
            return;
        }

        // Deny all other cases (E.g. there is no currentAccount)
        setIsAuthorized(false);
    }, [currentAccount, products, roles, location.pathname, hasAccess, hasRole, productsAll, rolesAll, checkAdmin, getAccess, productsOnly]);

    useEffect(() => {
        if (navigateOnError && isAuthorized === false && props.errorElement === undefined && location.pathname !== "/") {
            navigate("/");
        }
    }, [isAuthorized, props.errorElement, location, navigate, navigateOnError]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            {isAuthorized ? (
                <div>{props.children}</div>
            ) : props.errorElement === undefined ? <div><p>Unauthorized</p></div> : <div>{props.errorElement}</div>}
        </MsalAuthenticationTemplate>
    );
};

// Only show a component if conditions are met
export const ComponentGuard = ({ products = [], roles = [], productsAll = false, productsOnly = false, rolesAll = false, checkAdmin = true, ...props }) => {
    return (
        <RouteGuard products={products} roles={roles} productsAll={productsAll} productsOnly={productsOnly} rolesAll={rolesAll} checkAdmin={checkAdmin} navigateOnError={false} errorElement={props.errorElement ? props.errorElement : null} {...props}>
            {props.children}
        </RouteGuard>
    );
};
