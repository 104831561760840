import { Collapse, Expand } from "iconoir-react";
import { useContext, useState, useEffect, useCallback } from "react";
import { useCollapse } from "react-collapsed";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { AiGenerated } from "src/components/content/exam/StructureSection";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { EditPencil } from "iconoir-react";
import RenderMath from "src/components/ui/RenderMath";
import { tokenizeText } from "src/components/content/exam/StructureUtil";
import LoadingTab from "src/components/ui/LoadingTab";
import { useHasRole } from "src/api/useHasRole";
import useSendEdit from "src/api/goodpoint/useSendEdit";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { EditAction } from "src/api/goodpoint/Const";
import HoverIconButton from "src/components/ui/HoverIconButton";


export default function StudentOverallFeedback() {
    const { examObject } = useContext(ExamContext);
    const { selectedStudent } = useContext(StudentsContext);
    const [isEdit, setIsEdit] = useState(false);
    const [overallFeedback, setOverallFeedback] = useState(null);
    const { sendEdit, isSending } = 
        useSendEdit(GoodPointApi.Grading.Edit.Student(examObject.id, selectedStudent?.id));

    const onClickSave = useCallback((newContent) => {
        sendEdit(
            [{"action": EditAction.MODIFY, "keys": ["overall_feedback"], "value": newContent}], 
            () => { setOverallFeedback(newContent); setIsEdit(false); }
        );
    }, [sendEdit]);

    const { getCollapseProps, getToggleProps, isExpanded, setExpanded } = useCollapse({
        collapsedHeight: 120,
        hasDisabledAnimation: true
    });

    // Reset the feedback once the selected student changes.
    useEffect(() => {
        setOverallFeedback(selectedStudent?.overall_feedback);
    }, [selectedStudent?.overall_feedback]);

    const isReadOnly = useHasRole('student');

    return (
        <div className="student-feedback">
            <div className="feedback-header flex-row justify-content-space-between align-center">
                <div className="flex-row section-heading-left gap-small">
                    <b>Student Feedback</b>
                    <AiGenerated />
                </div>
                <div className="section-heading-controls flex flex-row gap-2">
                    {
                        !isReadOnly && 
                        <HoverIconButton
                            Icon={EditPencil}
                            onClick={() => { setIsEdit(true); setExpanded(true); }}
                            tooltip="Edit content"
                            tooltipPlacement="left"
                            disabled={isEdit}
                        />
                    }
                    <button 
                        className={
                            `btn-clickable flex flex-row gap-1 p-1 px-2 items-center rounded-md ` + 
                            `${isExpanded ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"}`
                        }
                        {...getToggleProps()}
                    >
                        {
                            isExpanded 
                            ? <><Collapse width="1.2em" strokeWidth={2.0}/><p>Collapse</p></>
                            : <><Expand width="1.2em" strokeWidth={2.0}/><p>Expand</p></>
                        }
                    </button>
                </div>
            </div>
            {overallFeedback ? (
                <div {...getCollapseProps()}>
                    {
                        isEdit 
                        ? <RichMathEditor
                            initialTokens={tokenizeText(overallFeedback)}
                            isSaving={isSending}
                            saveContent={onClickSave}
                            closeEditor={() => setIsEdit(false)}
                        />
                        : <RenderMath tokens={tokenizeText(overallFeedback)} />
                    }
                </div>
            ) : (
                <LoadingTab />
            )}
        </div>
    );
}