import { Tooltip } from "@mui/material";
import { FastArrowUp, FastArrowDown, QuestionMark } from "iconoir-react";
import { useContext, useState, useMemo } from "react";
import QuestionFeedbackStructure from "src/components/content/exam/grading-structure/question-feedback/QuestionFeedbackStructure";
import { isAnyFlagged } from "src/components/content/exam/StructureUtil";
import Divider from "src/components/layout/Divider";
import IconCheckbox from "src/components/ui/IconCheckbox";
import ClassGradingContext from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGradingContext";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";


export default function QuestionFeedback() {
    const { gradeBoundaries } = useContext(GradingContext);
    const { questionFeedback } = useContext(ClassGradingContext);

    const [, setIsFilterOnlyFlagged] = useState(false);
    const [isFilterOnlyMistakes, setIsFilterOnlyMistakes] = useState(false);
    const [orderBy, setOrderBy] = useState("index");
    const [isAscendingOrder, setIsAscendingOrder] = useState(true);

    const sortedQuestionFeedback = useMemo(() => {
        if (!questionFeedback) return null;
        return questionFeedback
            .sort((feedback1, feedback2) => {
                var result =
                    (orderBy === "percentage")
                        ? (
                            (feedback1["total_marks"] / feedback1["max_total_marks"]) - 
                            (feedback2["total_marks"] / feedback2["max_total_marks"])
                        )
                        : feedback1[orderBy] - feedback2[orderBy];
                return isAscendingOrder ? result : -result;
            });
    }, [isAscendingOrder, orderBy, questionFeedback]);

    const hasFlagged = useMemo(() => {
        if (!questionFeedback) return;
        return isAnyFlagged(questionFeedback);
    }, [questionFeedback]);

    const hasMistakes = useMemo(() => {
        if (!questionFeedback) return;
        return Object.values(questionFeedback).some(
            (feedback) => feedback["total_marks"] < feedback["max_total_marks"]
        );
    }, [questionFeedback]);

    return (
        <div className="feedback-container">
            <div className="flex flex-row items-center justify-between">
                <div className="space-y-1 mb-2">
                    <div className={`flex flex-row items-center ${hasMistakes ? "" : "translucent"}`}>
                        <IconCheckbox
                            disabled={!hasMistakes}
                            onCheck={() => { setIsFilterOnlyMistakes(true) }}
                            onUncheck={() => { setIsFilterOnlyMistakes(false) }}
                        />
                        <p className="margin-small">Show only mistakes</p>
                    </div>
                    <div className={`flex flex-row items-center ${hasFlagged ? "" : "opacity-50"}`}>
                        <IconCheckbox
                            disabled={!hasFlagged}
                            onCheck={() => { setIsFilterOnlyFlagged(true) }}
                            onUncheck={() => { setIsFilterOnlyFlagged(false)} }
                        />
                        <div className="margin-small flex-row">
                            <p className="margin-x-small">Show only feedback needing review</p>
                            <sup>
                                <Tooltip 
                                    title="
                                        AI-Generated feedback can sometimes be wrong. You can review it to ensure 
                                        its quality. If it is accurate, click on (NEEDS REVIEW) to remove the warning.
                                    "
                                    placement="right"
                                >
                                    <QuestionMark 
                                        color="var(--zanista-orange-dark)" className="cursor-pointer" width={20}
                                        strokeWidth={2.0}
                                    />
                                </Tooltip>
                            </sup>
                        </div>
                    </div>
                </div>
                <div className="feedback-order-by flex-row align-center margin-small mb-4">
                    <p>Order by:</p>
                    <select
                        className="order-by-select padding-x-small font-size-large margin-small"
                        value={orderBy}
                        onChange={(event) => setOrderBy(event.target.value)}
                    >
                        <option value="index">Question Number</option>
                        <option value="marks">Marks</option>
                        <option value="percentage">Percentage</option>
                    </select>
                    <div
                        className="cursor-pointer"
                        onClick={() => setIsAscendingOrder(!isAscendingOrder)}
                    >
                        {isAscendingOrder ? (
                            <FastArrowUp color="var(--zanista-orange-dark)" />
                        ) : (
                            <FastArrowDown color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                </div>
            </div>
            <Divider />
            <QuestionFeedbackStructure
                questionFeedback={sortedQuestionFeedback}
                // setQuestionFeedback={setQuestionFeedback}
                isFilterOnlyMistakes={isFilterOnlyMistakes}
                gradeBoundaries={gradeBoundaries}
            />
        </div>
    );
}
