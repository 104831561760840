import './ComingSoonPage.css'
import { Link, useLocation } from 'react-router-dom';
import Footer from 'src/pages/dashboard/Footer';
import logo from 'src/assets/logo.png'

function ComingSoon({ title = undefined, subtitle = undefined, buttons = undefined }) {
    const location = useLocation();

    if (buttons === undefined) {
        buttons = [
            {
                text: "Request access",
                link: `mailto:info@zanista.ai?subject=Request access&body=Hi, I would like to request access to ${location.pathname.replace("/", "")}. Please reach out to me by email.`
            },
            {
                text: "Find out more",
                link: "https://zanista.ai"
            }
        ]
    }

    return (
        <div className='coming-soon-screen centered'>
            <Link to="/">
                <img src={logo} alt="Zanista AI" />
            </Link>
            <h2>{title ? title : "Thanks for signing up"}</h2>
            <p>{subtitle ? subtitle : "We will be in touch shortly to approve your request for access."}</p>
            <div className='row'>
                {
                    buttons.map((button, index) => {
                        return (
                            <Link to={button.link} key={index}
                                className='coming-soon-button btn-clickable'>
                                {button.text}
                            </Link>
                        )
                    })
                }
            </div>
        <Footer />
        </div>
    );
}

export default ComingSoon;
