import { createContext } from "react";

const GradingContext = createContext({
    gradingType: null,
    setGradingType: (_t) => {},
    examResults: null,
    setExamResults: (_e) => {},
    gradeBoundaries: null,
    setGradeBoundaries: (_gb) => {},
    clickedStudent: null,
    setClickedStudent: (_cs) => {},
    waitingForMarksUpdate: false,
    setWaitingForMarksUpdate: (_m) => {}
});

export default GradingContext;