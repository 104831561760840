import StructureSection from "src/components/content/exam/StructureSection";
import { StructureItem } from "src/components/content/exam/StructureItem";
import { useContext, useState, useEffect, useMemo, useCallback } from "react";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { Tooltip } from "@mui/material";
import { useHasRole } from "src/api/useHasRole";
import { calcGrade } from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import { getFromStructure, isAnyFlagged } from "src/components/content/exam/StructureUtil";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import { TailSpin } from "react-loader-spinner";
import { EditAction } from "src/api/goodpoint/Const";
import StudentGradingContext from "src/pages/goodpoint/exam/tabs/grading/student-grading/StudentGradingContext";


function StudentFeedbackItemHeader({ itemData, indexHierarchy, nestingLevel }) {
    const [warningOpen, setWarningOpen] = useState(false);
    const { saveEdit, editStructure } = useContext(ExamStructureContext);
    const { gradeBoundaries, setExamResults } = useContext(GradingContext);
    const { waitingForMarksUpdate, setWaitingForMarksUpdate, setStudentResults } = useContext(StudentGradingContext);

    //this is actually to keep track of the previous mark to update the difference but i'm saur lazy to change it 
    const [marks, setMarks] = useState(parseFloat(itemData.total_marks));
    useEffect(() => {
        setMarks(parseFloat(itemData?.total_marks ?? "0.0"));
    }, [itemData?.total_marks]);
    const maxMarks = useMemo(() => itemData?.max_total_marks, [itemData?.max_total_marks]);
    const percentage = useMemo(() => 100.0 * marks / maxMarks, [maxMarks, marks]);
    const grade = useMemo(() => calcGrade(percentage, gradeBoundaries), [percentage, gradeBoundaries]);
    const flagged = useMemo(() => isAnyFlagged(itemData), [itemData]);

    const readOnly = useHasRole('student');

    const handleMarkChange = useCallback((event) => {
        const newMark = parseFloat(event.target.value);
        if (newMark === itemData.total_marks) return;
        if (warningOpen) {
            event.target.value = itemData.total_marks;
            setWarningOpen(false);
            return;
        }
        setWaitingForMarksUpdate(true);
        const editAction = {
            "action": EditAction.MARKS, "indexes": [...indexHierarchy, itemData.index], "value": newMark
        };
        // Sending a mark edit to the backend will return the newly updated structure item including its sub items
        // Therefore, we must update the structure with this new item
        saveEdit([editAction], (result) => {
            setExamResults(null);
            setStudentResults(null);
            editStructure({
                "action": EditAction.REPLACE,
                "indexes": (indexHierarchy.length === 0) ? [itemData.index] : indexHierarchy, 
                "item": result
            });
            setWaitingForMarksUpdate(false);
        });
    }, [editStructure, indexHierarchy, itemData.index, itemData.total_marks, saveEdit, setExamResults, setStudentResults, setWaitingForMarksUpdate, warningOpen]);

    return (
        <div className="flex flex-row justify-between fill-width">
            <p className="text-left"><b>{nestingLevel > 0 ? "Part " : ""} {itemData["name"]}</b></p>
            {
                flagged && <p className="text-right text-zanista-red">(NEEDS REVIEW)</p>
            }
            <div className="grid grid-cols-4 gap-2">
                <div className="flex flex-row gap-2 col-span-2 items-center">
                    {
                        readOnly
                            ? <p>{marks} / {maxMarks} Marks</p>
                            : <>
                                {
                                    waitingForMarksUpdate &&
                                    <TailSpin width="1em" height="1em" color="black" strokeWidth={3.0} />
                                }
                                <Tooltip
                                    open={warningOpen}
                                    onOpen={() => setWarningOpen(true)}
                                    onClose={() => setWarningOpen(false)}
                                    title="Invalid marks"
                                    disableHoverListener={true}
                                    disableFocusListener={true}
                                >
                                    <input
                                        type="number"
                                        key={`${itemData.name}_${marks}`}
                                        onBlur={handleMarkChange}
                                        onClick={(event) => event.stopPropagation()}
                                        onChange={(event) => {
                                            const newMarks = parseFloat(event.target.value);
                                            setWarningOpen((newMarks > itemData.max_total_marks) || (newMarks < 0.0));
                                        }}
                                        defaultValue={marks}
                                        className={
                                            `w-12 ${
                                                waitingForMarksUpdate ? "bg-slate-300" : (
                                                    warningOpen ? "bg-zanista-red-light" : "bg-white"
                                                )
                                            } ` + 
                                            `rounded-md focus:outline-accent text-right`
                                        }
                                        disabled={readOnly}
                                    />
                                </Tooltip>
                                <p> / {maxMarks}</p>
                                Marks
                            </>
                    }
                </div>
                <p>({percentage.toFixed(1)}%)</p>
                <p>Grade {grade}</p>
            </div>
        </div>
    );
}

function StudentFeedbackItemContent({ itemData, editKeysHierarchy }) {
    const { rubric } = useContext(RubricContext);
    const questionContent = (
        rubric && editKeysHierarchy && 
        getFromStructure(rubric, [...editKeysHierarchy, "question_content"])
    );
    const { feedback_content: feedback } = itemData;

    return (
        <div className="feedback-content pt-2">
            {
                questionContent &&
                <StructureSection
                    heading={"Question Content"}
                    content={questionContent}
                    hide
                />
            }
            {
                feedback &&
                <StructureSection
                    content={feedback}
                    heading={"Student Feedback"}
                    editKeys={["student_question_feedback", ...editKeysHierarchy, "feedback_content"]}
                    aiGenerated
                >
                </StructureSection>
            }
        </div>
    );
}

export default function StudentFeedbackItem({ itemData, ...props }) {
    const { isFilterOnlyFlagged, isFilterOnlyMistakes } =
        useContext(ExamStructureContext).itemCommonProps;

    if (isFilterOnlyMistakes && !(itemData.total_marks < itemData.max_total_marks)) {
        return null;
    }
    // WARNING: inefficient, find better way to detect if any in hierarchy is flagged
    if (isFilterOnlyFlagged && !isAnyFlagged(itemData)) {
        return null;
    }

    return (
        <StructureItem
            itemData={itemData}
            HeaderComponent={StudentFeedbackItemHeader}
            ContentComponent={StudentFeedbackItemContent}
            {...props}
        />
    );
}
