import { protectedResources } from 'src/auth/AuthConfig';
import { queryParamsString } from "src/util/StringUtil";

const PROD_ENDPOINT = protectedResources.apiGoodPoint.endpoint;
const LOCAL_ENDPOINT = "http://localho.st:5000/goodpoint"

const GoodPointApi = {
    Base: process.env.REACT_APP_IS_DEV === 'true' ? LOCAL_ENDPOINT : PROD_ENDPOINT
}

GoodPointApi.NewExam = `${GoodPointApi.Base}/new-exam`
GoodPointApi.ListExams = `${GoodPointApi.Base}/exams`

const Exam = (id) => `${GoodPointApi.Base}/exam/${id}`
Exam.Name = (id) => `${Exam(id)}/name`
Exam.Info = (id) => `${Exam(id)}/info`
Exam.Share = (id) => `${Exam(id)}/share`
Exam.Delete = (id) => `${Exam(id)}/delete`
GoodPointApi.Exam = Exam

const Rubric = (id) => `${Exam(id)}/rubric`
Rubric.Upload = (id) => `${Rubric(id)}/upload`
Rubric.Upload.Delete = (id, filename) => `${Rubric.Upload(id)}/${filename}`
Rubric.Config = (id) => `${Rubric(id)}/config`
Rubric.Process = (id) => `${Rubric(id)}/process`
Rubric.Edit = (id) => `${Rubric(id)}/edit`
Rubric.Logs = (id) => `${Rubric(id)}/logs`
// Rubric.Get = (id, question = null, sub_question = null) => `${Rubric(id)}?${queryParamsString({ question, sub_question })}`
Rubric.All = (id, type) => `${Rubric(id)}/all?${queryParamsString({ type })}`
Rubric.Combined = {
    View: (id) => `${Rubric(id)}/combined/view`,
    Download: (id) => `${Rubric(id)}/combined/download`
}
Rubric.Original = {
    View: (id, filename) => `${Rubric(id)}/original/view/${filename}`,
    Download: (id, filename) => `${Rubric(id)}/original/download/${filename}`
}
Rubric.Link = (id) => `${Rubric(id)}/combined/link`
Rubric.View = (id, rubricType) => `${Rubric(id)}/${rubricType}/view`
Rubric.Download = (id, rubricType) => `${Rubric(id)}/${rubricType}/download`
Rubric.Output = {
    Download: (id) => `${Exam(id)}/rubric/output/download`
}
Rubric.Reset = (id) => `${Rubric(id)}/reset`
GoodPointApi.Rubric = Rubric

const Answers = (id) => `${Exam(id)}/answers`
Answers.Upload = (id) => `${Answers(id)}/upload`
Answers.Upload.Delete = (id, filename) => `${Answers.Upload(id)}/${filename}`
Answers.Config = (id) => `${Answers(id)}/config`
Answers.Process = (id) => `${Answers(id)}/process`
Answers.Logs = (id) => `${Answers(id)}/logs`
Answers.Students = (id) => `${Answers(id)}/students`
Answers.Student = {
    Config: (id, studentId) => `${Answers(id)}/${studentId}/config`,
    All: (id, studentId = null) => `${Answers(id)}${studentId ? `/${studentId}` : ""}/all`,
    Edit: (id, studentId) => `${Answers(id)}/edit/${studentId}`,
    Link: (id, studentId) => `${Answers(id)}/${studentId}/link`,
    Download: (id, type, studentId) => `${Answers(id)}/${studentId}/${type}/download`,
}
Answers.DownloadAll = (id) => `${Answers(id)}/all/download`
Answers.Original = {
    View: (id, filename) => `${Answers(id)}/original/view/${filename}`,
    Download: (id, filename) => `${Answers(id)}/original/download/${filename}`,
    Link: (id, filename) => `${Answers(id)}/original/link/${filename}`
}
Answers.Reset = (id) => `${Answers(id)}/reset`
GoodPointApi.Answers = Answers

const Grading = (id) => `${Exam(id)}/grading`
Grading.Config = (id) => `${Grading(id)}/config`
Grading.Process = (id) => `${Grading(id)}/process`
Grading.Logs = (id) => `${Grading(id)}/logs`
Grading.Edit = {
    Class: (id) => `${Grading(id)}/edit/class`,
    Student: (id, studentId) => `${Grading(id)}/edit/students/${studentId}`
}
Grading.Results = {
    Class: (id) => `${Grading(id)}/results/class`,
    Student: (id, studentId) => `${Grading(id)}/results/students/${studentId}`
}
const Feedback = {
    Class: (id) => `${Grading(id)}/feedback/class/all`,
    Student: (id, studentId) => `${Grading(id)}/feedback/students/${studentId}`
}
Feedback.Student.All = (id) => `${Grading(id)}/feedback/students/all`
Feedback.Class.Download = (id, filetype = "pdf") => `${Grading(id)}/feedback/class/download?filetype=${filetype}`
Feedback.Student.Download = (id, student_id, filetype = "pdf") => 
    `${Grading(id)}/feedback/students/${student_id}/download?filetype=${filetype}`
Grading.Feedback = Feedback
Grading.Plots = {
    Class: (id, plotName) => `${Grading(id)}/plots/class/${plotName}`,
    Student: (id, studentId, plotName) => `${Grading(id)}/plots/students/${studentId}/${plotName}`
}
Grading.Output = {
    Download: (id) => `${Exam(id)}/grading/output/download`
}
Grading.Reset = (id) => `${Grading(id)}/reset`
GoodPointApi.Grading = Grading

const Output = {
    Download: (id) => `${Exam(id)}/output/download`
}
GoodPointApi.Output = Output

export default GoodPointApi;