import React, { useState } from "react";
import "./ChatBox.css";
import useSendChat from "src/api/newswitch/useSendChat";

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);

  // The entire conversation in the format the API expects:
  const [messages, setMessages] = useState([
    // Optionally start with a system message:
    // { role: "system", content: "You are a helpful assistant." }
  ]);

  const [currentInput, setCurrentInput] = useState("");
  const sendChatMessage = useSendChat();

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = async () => {
    if (currentInput.trim() === "") return;

    // 1. Add user message to the conversation
    const updatedMessages = [
      ...messages,
      { role: "user", content: currentInput }
    ];

    // For optimistic UI update (you see your own message right away)
    setMessages(updatedMessages);

    // Clear the input field
    setCurrentInput("");

    // 2. Call the API with the *entire* conversation so far
    try {
      const botResponse = await sendChatMessage(updatedMessages);
      // 3. Add the assistant message to the conversation
      setMessages((prev) => [
        ...prev,
        { role: "assistant", content: botResponse }
      ]);
    } catch (error) {
      // Optionally handle error in UI
      console.error("Chat error: ", error);
    }
  };

  return (
    <div className="chatbox-container">
      {isOpen && (
        <div className="chatbox">
          <div className="chatbox-header">
            <span>Chatbot</span>
            <button onClick={toggleChat}>&times;</button>
          </div>
          <div className="chatbox-messages">
            {messages.map((msg, index) => {
              // We'll transform "role" into a className or label
              const senderClass = msg.role === "user" ? "user" : "bot";
              return (
                <div key={index} className={`message ${senderClass}`}>
                  {msg.content}
                </div>
              );
            })}
          </div>
          <div className="chatbox-input">
            <input
              type="text"
              value={currentInput}
              onChange={(e) => setCurrentInput(e.target.value)}
              placeholder="Ask me something..."
            />
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      )}
      <button className="chatbox-toggle" onClick={toggleChat}>
        {isOpen ? "-" : "💬"}
      </button>
    </div>
  );
};

export default ChatBox;
