import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { protectedResources } from 'src/auth/AuthConfig';

const getEndpoint = protectedResources.apiBlog.endpoint + "/all";

const Paperpal = () => {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        const fetchBlogPostsList = async () => {
            try {
                const response = await fetch(getEndpoint);
                if (!response.ok) {
                    throw new Error("Failed to fetch blog posts");
                }
                const responseData = await response.json();
                setPosts(responseData.reverse());  // Assuming response data is an array of blog posts
            } catch (error) {
                console.error("Error fetching blog posts:", error);
            }
        };
        fetchBlogPostsList();
    }, []);

    return (
        <div>
            <Outlet context={{ posts }} />
        </div>
    );
};

export default Paperpal;
