import "./EditableExamName.css"
import { useContext, useEffect, useRef, useState } from "react";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import { useHasRole } from "src/api/useHasRole";
import useSendExamName from "src/api/goodpoint/useSendExamName";


export default function EditableExamName({ maxLength = 60 }) {
    const disabled = useHasRole("student");
    const { examObject, updateExamObject } = useContext(ExamContext);
    const sendName = useSendExamName(examObject.id);

    const [inputValue, setInputValue] = useState(examObject.name);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!inputRef) return;
        inputRef.current.textContent = examObject.name;
    }, [examObject.name]);

    function onKeyDown(event) {
        if (event.keyCode !== 8 && inputRef.current.textContent.length >= maxLength) {
            event.preventDefault();
            return;
        }
    }

    function onBlur() {
        if (inputRef.current.textContent.length === 0) {
            inputRef.current.textContent = `Exam #${examObject.id}`;
        }
        setInputValue(inputRef.current.textContent);
    }

    useEffect(() => {
        if (inputValue === examObject.name) return;
        updateExamObject({ name: inputValue } );
        sendName(inputValue);
    }, [examObject.name, inputValue, sendName, updateExamObject]);

    return (
        <div className={`editable-exam-name flex-row align-center fill-width display-inline-block`}>
            <span
                ref={inputRef}
                className={
                    `editable-exam-name-span margin-none py-2 fill-width ` +
                    `word-wrap-break-word display-block vertical-align-middle`
                }
                contentEditable={!disabled}
                suppressContentEditableWarning
                spellCheck={true}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
            />
        </div>
    );
}