import React, { useEffect, useState } from 'react';
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import useCallApi from 'src/api/useCallApi';
import { protectedResources } from 'src/auth/AuthConfig';
import TimingBar from 'src/pages/newswitch/components/TimingBar';
import Popup from 'reactjs-popup';
import { Tooltip } from '@mui/material';
import { TailSpin } from 'react-loader-spinner';

function NewsWitchPipelineManager() {
    
    const callAPI = useCallApi();

    const [runData, setRunData] = useState(undefined);
    const [selectedStartTime, setSelectedStartTime] = useState(undefined);
    const [chunks, setChunks] = useState(undefined);
    const [numRuns, setNumRuns] = useState(5);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsLoading(true);
            callAPI("GET", protectedResources.apiNewsWitch.endpoint + `/russell_chunks?num_runs=${numRuns}`)?.then((response) => {
                if (response.status === 200) {
                    response.json().then(response => {
                        setRunData(response["runs"]);
                        setIsLoading(false);
                    });
                }
            });
        }, 10000);

        return () => clearInterval(interval);
    }, [callAPI, numRuns]);

    useEffect(() => {
        if (runData === undefined) return;
        const runKeys = Object.keys(runData);
        let newChunks = undefined;
        if (selectedStartTime !== undefined && runKeys.includes(selectedStartTime)) {
            newChunks = runData[selectedStartTime]["chunks"];
        } else {
            // Select the most recent run
            const mostRecentTimestamp = runKeys.reduce((max, key) => {
                return Math.max(max, parseInt(key));
            }, 0);
            newChunks = runData[mostRecentTimestamp]["chunks"];
        }
        setChunks(newChunks);
    }, [runData, selectedStartTime]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Admin Portal" href="/admin" />
                <Title title="NewsWitch Pipeline Tracker" />
                <div className='flex-row justify-between'>
                    <StartTimeSelector times={runData ? Object.keys(runData) : []} setSelectedStartTime={setSelectedStartTime} />
                    <div className='flex-row gap-2 items-center'>
                        <TailSpin visible={isLoading} width={18} height={18} color='var(--zanista-orange-mid)' strokeWidth={6.0} wrapperClass="padding-small" />
                        <button 
                            className="text-xs p-1 px-2 my-1 btn-clickable hover:scale-105 bg-zanista-orange-mid rounded hover:bg-zanista-orange-dark" 
                            onClick={() => {setNumRuns(numRuns + 5); setIsLoading(true);}}
                            disabled={isLoading}
                        >
                            Load More Runs ({numRuns + 5})
                        </button>
                    </div>
                </div>
                <div className="flex-col gap-8">
                    <ChunkTimeline chunks={chunks} />
                    <ChunkList chunks={chunks} />
                </div>
            </div>
        </div>
    );
}

function StartTimeSelector({ times, setSelectedStartTime = (startTime) => {} }) {
    if (times === undefined) {
        times = []
    }

    return (
        <div className='flex-col m-1 w-fit'>
            <select
                className="text-gray-700 rounded outline outline-1 px-1 min-h-6 bg-white" 
                onChange={(e) => setSelectedStartTime(e.target.value)}>
                {times.sort((a, b) => parseInt(b) - parseInt(a)).map(time => {
                    const timeStr = new Date(parseInt(time) * 1000).toLocaleString();
                    return (
                        <option value={parseInt(time)}>{timeStr}</option>
                    )
                })}
            </select>
        </div>
    );
}

// TODO: An overall progress bar for the pipeline.
// TODO: An overview of the pipeline. How many chunks are in each status.
// TODO: A list of chunks (ordered) in a scrollable list. Each chunk should have a status, last_seen and a progress bar.

function ChunkList({ chunks }) {
    if (chunks === undefined) {
        chunks = []
    }
    chunks.sort((a, b) => {
        const aSections = a["timing"]["sections"] ? Object.keys(a["timing"]["sections"]).length : 0;
        const bSections = b["timing"]["sections"] ? Object.keys(b["timing"]["sections"]).length : 0;
        return bSections - aSections;
    });
    return (
        <div className='flex-col rounded outline outline-1 m-1 max-h-96'>
            <div className='grid grid-cols-5 m-1 mx-2 px-4 py-1'>
                <h3>Chunk No.</h3>
                <h3>Last Stage</h3>
                <h3>Last Seen</h3>
                <h3>Status</h3>
                <h3>Total Time</h3>
            </div>
            <div className='flex-col overflow-y-scroll'>
                {chunks.map(chunk => <ChunkRow chunk={chunk} />)}
                {chunks.length === 0 && <p className='m-2'>Loading...</p>}
            </div>
        </div>
    );
}

function ChunkRow({ chunk }) {

    let stage = "create_jobs";
    let last_seen = chunk["timing"]["start_time"]
    let status = "Unknown";
    let num_news = null;

    if (chunk["timing"]["sections"] !== undefined) {
        const sections = Object.entries(chunk["timing"]["sections"]).sort(([k1, v1], [k2, v2]) => v2['index'] - v1['index'])
        if (sections.length > 0) {
            stage = sections[0][0]
            if (sections[0][1]["end_time"]) {
                last_seen = sections[0][1]["end_time"]
                status = "In Stage"
            } else {
                last_seen = sections[0][1]["start_time"]
                status = "In Stage"
            }
        }
        if (chunk["timing"]["sections"]["get_news"] !== undefined) {
            num_news = chunk["timing"]["sections"]["get_news"]["additional_info"]["num_news"];
        }
    }

    if (chunk["timing"]["end_time"] !== undefined) {
        status = "Finished";
    }

    const [timeTaken, setTimeTaken] = useState(0);
    useEffect(() => {
        if (chunk["timing"]["end_time"]) {
            const startTime = timestampToDate(chunk["timing"]["start_time"]);
            const endTime = timestampToDate(chunk["timing"]["end_time"]);
            // @ts-ignore
            setTimeTaken(endTime - startTime);
        } else {
            const startTime = timestampToDate(chunk["timing"]["start_time"]);
            const endTime = new Date();
            // @ts-ignore
            setTimeTaken(endTime - startTime);
        }
    }, [chunk]);

    const timestampToDate = (timestamp) => {
        return new Date(timestamp * 1000)
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        if (hours === 0) {
            if (minutes === 0) {
                return `${seconds}s`;
            }
            return `${minutes}m ${seconds}s`;
        }

        return `${hours}h ${minutes}m`;
    };

    return (
        <Popup trigger={
            <Tooltip 
                className="cursor-pointer"   
                placement='right' 
                title={num_news !== null ? `${num_news} news found` : ""}
            >
                <div className='grid grid-cols-5 m-1 mx-2 px-4 py-1 bg-zanista-orange-light rounded-small hover:bg-zanista-orange-mid'>
                    <h3>Chunk {chunk["chunk_num"]}</h3>
                    <h3>{stage}</h3>
                    <h3>{timestampToDate(last_seen).toLocaleTimeString()}</h3>
                    <h3>{status}</h3>
                    <h3>{formatTime(timeTaken)}</h3>
                </div>
            </Tooltip>
        } position="left center" arrowStyle={{ color: "#0000"}} >
            <div className='bg-white outline outline-1 rounded mx-2 p-1'>
                <TimingBar timings={chunk["timing"]} />
            </div>
        </Popup>
    );
}

function ChunkTimeline({ chunks }) {

    function getCounts(chunks) {

        if (chunks === undefined || chunks.length === 0) {
            return {
                "chunks": chunks,
                "counts": {},
                "keys": []
            }
        }

        const orderedChunks = chunks.sort((chunk1, chunk2) => {
            if (chunk1["timing"]["sections"] === undefined && chunk2["timing"]["sections"] === undefined) {
                return 0
            }
            if (chunk1["timing"]["sections"] === undefined) {
                return 1
            }
            if (chunk2["timing"]["sections"] === undefined) {
                return -1
            }
    
            let longest1 = 0
            const sections1 = Object.entries(chunk1["timing"]["sections"]).sort(([k1, v1], [k2, v2]) => v2['index'] - v1['index'])
            if (sections1.length > 0) {
                longest1 = sections1[0][1]['index']
            }
    
            let longest2 = 0
            const sections2 = Object.entries(chunk2["timing"]["sections"]).sort(([k1, v1], [k2, v2]) => v2['index'] - v1['index'])
            if (sections2.length > 0) {
                longest2 = sections2[0][1]['index']
            }
    
            return longest2 - longest1
        });
    
        const furthestChunk = orderedChunks[0];

        const keys = [];
        keys.push("create_jobs");

        if (furthestChunk !== undefined && furthestChunk["timing"]["sections"] !== undefined) {
            Object.entries(furthestChunk["timing"]["sections"]).sort(([k1, v1], [k2, v2]) => v1['index'] - v2['index']).forEach(d => keys.push(d[0]));
        }
    
        keys.push("finish");
    
        const counts = keys.reverse().reduce((acc, key) => {
            acc[key] = chunks.reduce((count, chunk) => {
                if (chunk["timing"]["sections"] === undefined) {
                    if (key === "create_jobs") {
                        return count + 1;
                    }
                    return count;
                }
                const sections = Object.entries(chunk["timing"]["sections"]).sort(([k1, v1], [k2, v2]) => v2['index'] - v1['index'])
                if (sections.length > 0) {
                    if (sections[0][0] === key) {
                        if (chunk["finished"] && key !== "finish") {
                            return count;
                        }
                        return count + 1;
                    } else if (chunk["finished"] && key === "finish") {
                        return count + 1;
                    }
                }
                return count;
            }, 0);
            return acc;
        }, {});
    
        keys.reverse();

        return {
            "chunks": chunks,
            "counts": counts,
            "keys": keys
        }
    }

    const { counts, keys } = getCounts(chunks);

    return (
        <div className='flex-col rounded outline outline-1 m-1 p-2'>
            <div className='grid grid-cols-3 my-2'>
                <h3 className='font-bold'>Stages</h3>
                <h3 className='font-bold'>No. Chunks Completed</h3>
                <h3 className='font-bold'>Total Chunks: {chunks ? chunks.length : ""}</h3>
            </div>
            <hr />
            {
                counts && keys &&
                keys.map(key => {
                    const percent = counts[key] / chunks.length
                    return (
                        <div className='grid grid-cols-3 my-2'>
                            <h3>{key}</h3>
                            <p className='mr-2'>{counts[key]}</p>
                            <div className='bg-slate-200 rounded h-6'>
                                <div
                                    className='bg-blue-500 h-6 rounded'
                                    style={{ width: `${(percent) * 100}%` }}
                                ></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default NewsWitchPipelineManager;