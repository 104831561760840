import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Latex from "react-latex-next";
import ExpandCollapseButtons from "src/components/layout/expand-collapse/ExpandCollapseButtons";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { tokenizeText } from "src/components/content/exam/StructureUtil";
import { Tooltip } from "@mui/material";
import StructureSection from "src/components/content/exam/StructureSection";
import RenderMath from "src/components/ui/RenderMath";
import { useHasRole } from "src/api/useHasRole";
import { 
    BadgeCheck, InfoCircle, WarningTriangle, PageStar, EditPencil, NavArrowDown, NavArrowRight 
} from "iconoir-react";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { EditAction } from "src/api/goodpoint/Const";
import HoverIconButton from "src/components/ui/HoverIconButton";
import { RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import { TailSpin } from "react-loader-spinner";


const StepTabText = {
    SOLUTION: "Solution",
    EXPECTATION: "Expectation",
    COMMON_ERRORS: "Common Errors",
    MARKING: "Marking",
};

const StepTabIcon = {
    SOLUTION: BadgeCheck,
    EXPECTATION: InfoCircle,
    COMMON_ERRORS: WarningTriangle,
    MARKING: PageStar,
};

const EditableContent = ({ label, content, editKeys }) => {
    const { saveEdit, isSavingEdit, editStructure } = useContext(ExamStructureContext);
    const readOnly = useHasRole("student");
    const [isEdit, setIsEdit] = useState(false);

    const onClickSave = useCallback((newContent) => {
        const editAction = {"action": EditAction.MODIFY, "keys": editKeys, "value": newContent};
        saveEdit([editAction], () => { editStructure(editAction); setIsEdit(false); });
    }, [editKeys, editStructure, saveEdit]);

    return (
        <div className="w-full">
            <div className="flex flex-row w-full justify-between items-center">
                <h2 className="text-lg"><b>{label}: </b></h2>
                {
                    !readOnly && 
                    <HoverIconButton
                        tooltip="Edit content"
                        tooltipPlacement="right"
                        Icon={EditPencil}
                        onClick={() => setIsEdit(e => !e)}
                        disabled={isEdit}
                    />
                }
            </div>
            {
                isEdit &&
                <RichMathEditor
                    initialTokens={tokenizeText(content)}
                    saveContent={onClickSave}
                    closeEditor={() => setIsEdit(false)}
                    isSaving={isSavingEdit}
                />
            }
            {
                !isEdit &&
                <RenderMath tokens={tokenizeText(content)} />
            }
        </div>
    );
};

function StepTabs({ stepTab, setStepTab }) {
    return (
        <div className="step-tabs flex-col gap-mid">
            {Object.entries(StepTabText).map(([k, v], i) => {
                const selected = stepTab === v;
                const Icon = StepTabIcon[k];
                return (
                    <button
                        onClick={() => setStepTab(v)}
                        className={
                            `btn-clickable flex flex-row gap-1 items-center w-full rounded-md text-nowrap p-1 pr-2 ` + 
                            `${selected ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"} ` + 
                            `scale-105 duration-100 text-sm`
                        }
                    >
                        <Icon color={selected ? "black" : "var(--zanista-orange-dark)"} width="1.25em" className="margin-0"/>
                        {v}
                    </button>
                );
            })}
        </div>
    );
}

function Step({ stepIndex, step, indexHierarchy, editKeysHierarchy }) {
    const { editStructure, saveEdit } = useContext(ExamStructureContext);
    const { waitingForMarksUpdate, setWaitingForMarksUpdate } = useContext(RubricContext);

    // editKeys := [ etc. etc. , current question index, "steps", current step index]
    const editKeys = [...editKeysHierarchy, stepIndex];

    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetAll,
    } = useContext(ExpandCollapseContext);

    const { isExpanded, expand, collapse, toggle } = useExpandCollapse();

    useEffect(() => {
        isAllExpanded && expand();
    }, [isAllExpanded, expand]);
    useEffect(() => {
        isAllCollapsed && collapse();
    }, [isAllCollapsed, collapse]);

    const [stepTab, setStepTab] = useState(StepTabText.SOLUTION);

    const { 
        solution, expanded_solution: expandedSolution, expected,
        marks: stepMark, mark_deduction: markDeduction 
    } = step;

    const commonErrors = step.common_mistakes?.[0]?.[0] ?? "[missing]";

    const [warningOpen, setWarningOpen] = useState(false);

    const marksInputRef = useRef(null);
    const markDeductionInputRef = useRef(null);

    useEffect(() => {
        if (!waitingForMarksUpdate) {
            if (marksInputRef.current) {
                marksInputRef.current.value = stepMark;
            }
            if (markDeductionInputRef.current) {
                markDeductionInputRef.current.value = markDeduction;
            }
        }
    }, [stepMark, waitingForMarksUpdate, marksInputRef, markDeductionInputRef, markDeduction]);

    const handleStepMarks = useCallback((event) => {
        const newMark = parseFloat(event.target.value);
        if (newMark === stepMark) return;
        setWaitingForMarksUpdate(true);
        const editAction = {
            "action": EditAction.STEP_MARKS,
            "indexes": indexHierarchy, 
            "value": newMark, 
            "step_index": stepIndex
        };
        // Sending a mark edit to the backend will return the newly updated structure item including its sub items
        // Therefore, we must update the structure with this new item
        saveEdit([editAction], (result) => {
            editStructure({
                "action": EditAction.REPLACE,
                "indexes": indexHierarchy,
                "item": result
            });
            setWaitingForMarksUpdate(false);
        });
    }, [editStructure, stepIndex, indexHierarchy, saveEdit, setWaitingForMarksUpdate, stepMark]);

    const handleMarkDeductions = (event) => {
        const newMarkDeductions = parseFloat(event.target.value) || 0;
        if (newMarkDeductions > stepMark) {
            setWarningOpen(true);
        } else {
            const editAction = {
                "action": EditAction.MODIFY,
                "keys": [...editKeysHierarchy, stepIndex, "mark_deduction"],
                "value": newMarkDeductions,
            };
            saveEdit([editAction], () => { editStructure(editAction); })
            setWarningOpen(false);
        }
    };

    var stepContent = null;
    switch (stepTab) {
        case StepTabText.SOLUTION:
            stepContent = (
                <div>
                    <EditableContent
                        label="Solution"
                        content={solution ?? "[missing]"}
                        editKeys={[...editKeys, "solution"]}
                    />
                </div>
            );
            break;

        case StepTabText.EXPECTATION:
            stepContent = (
                <div>
                    <EditableContent
                        label="Expanded Solution"
                        content={expandedSolution}
                        editKeys={[...editKeys, "expanded_solution"]}
                    />

                    <p></p>
                    <br></br>
                    <EditableContent
                        label="Student Expectation"
                        content={expected}
                        editKeys={[...editKeys, "expected"]}
                    />
                </div>
            );
            break;

        case StepTabText.COMMON_ERRORS:
            stepContent = (
                <div>
                    <EditableContent
                        label="Common Errors"
                        content={commonErrors}
                        editKeys={[...editKeys, "common_mistakes", 0, 0]}
                    />
                    <p></p>
                    <br></br>
                    <b>Examples: </b>
                    <ul>
                        {" "}
                        {
                            []
                                .concat(...step.common_mistakes.map((cm) => cm[1]))
                                .map((v, i) => {
                                    return (
                                        <li key={i}>
                                            <RenderMath tokens={tokenizeText(v)} />
                                        </li>
                                    );
                                })
                        }{" "}
                    </ul>
                </div>
            );
            break;
        case StepTabText.MARKING:
            stepContent = (
                <div>
                    <b>Marks for this step: </b>
                    <p className="text-zanista-orange-dark flex flex-row gap-2 items-center">
                        <input
                            ref={marksInputRef}
                            type="number"
                            key={`${stepIndex}`} //forces rerender
                            onBlur={handleStepMarks}
                            defaultValue={stepMark}
                            className="w-12 text-right focus:outline-accent"
                        ></input>
                        Marks
                        {
                            waitingForMarksUpdate &&
                            <TailSpin width="1em" height="1em" color="black" strokeWidth={3.0} />
                        }
                    </p>
                    <p></p>
                    <br></br>
                    <b>Mark deductions for errors: </b>
                    <div className="text-zanista-orange-dark flex flex-row gap-2 items-center">
                        <Tooltip
                            open={warningOpen}
                            onOpen={() => setWarningOpen(true)}
                            onClose={() => setWarningOpen(false)}
                            title="Mark deduction cannot be more than step mark!"
                            disableHoverListener={true}
                            disableFocusListener={true}
                        >
                            <input
                                ref={markDeductionInputRef}
                                type="number"
                                key={`${stepIndex}`} // forces rerender
                                onBlur={handleMarkDeductions}
                                defaultValue={markDeduction}
                                className="w-12 text-right focus:outline-accent"
                            ></input>
                        </Tooltip>
                        Marks
                        {
                            waitingForMarksUpdate &&
                            <TailSpin width="1em" height="1em" color="black" strokeWidth={3.0} />
                        }
                    </div>
                </div>
            );
            break;
        default:
            break;
    }

    return (
        <div className="space-y-1">
            <div
                className="flex flex-row gap-2 justify-between w-full cursor-pointer items-center"
                onClick={() => {
                    toggle();
                    resetAll();
                }}
            >
                <div className="flex flex-row gap-1">
                    {
                        isExpanded ? <NavArrowDown width="1.2em"/> : <NavArrowRight width="1.2em"/>
                    }
                    <p className="text-black text-nowrap mr-1">
                        Step {stepIndex + 1}
                    </p>
                    <div className="step-heading-text fg-orange-dark">
                        <Latex>{step.step}</Latex>
                    </div>
                </div>
                <div className="flex-grow mx-1 border-t border-dotted border-zanista-orange-dark"></div>
                <p className="text-black text-nowrap">
                    {step.marks} Marks
                </p>
            </div>
            {
                isExpanded &&
                <div className="step-body flex flex-row gap-4 pl-5 pb-2 pt-1">
                    <StepTabs stepTab={stepTab} setStepTab={setStepTab} />
                    <div className="step-content font-size-medium">{stepContent}</div>
                </div>
            }
        </div>
    );
}

export default function RubricSteps({ steps, indexHierarchy, editKeysHierarchy }) {
    const expandCollapse = useExpandCollapse(false);

    if (!steps) {
        return null;
    }

    return (
        <ExpandCollapseContext.Provider value={expandCollapse}>
            <StructureSection
                includeDivider={false}
                heading={"Step-by-Step"}
                headingControls={
                    <ExpandCollapseButtons
                        props={{
                            iconSize: 17,
                            borderSize: "var(--border-thin)",
                            paddingSize: "0px 5px",
                            roundedSize: "var(--rounded-xsmall)",
                        }}
                    />
                }
                aiGenerated
            >
                {
                    <div className="my-2 steps-list space-y-2 pr-3 pl-1">
                        {
                            steps.map((s, i) => (
                                <Step
                                    key={i}
                                    step={s}
                                    stepIndex={i}
                                    editKeysHierarchy={editKeysHierarchy}
                                    indexHierarchy={indexHierarchy}
                                />
                            ))
                        }
                    </div>
                }
            </StructureSection>
        </ExpandCollapseContext.Provider>
    );
}
