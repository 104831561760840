import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";

// Returns the list of products that a user has access to, found by using the claims in the JWT issued by B2C AD
export default function useGetAccess() {
    const { instance } = useMsal();

    const getAccess = useCallback(() => {
        const currentAccount = instance.getActiveAccount();

        if (!currentAccount.idTokenClaims['extension_Access']) {
            return [];
        }

        const claimAccess = 
            typeof currentAccount.idTokenClaims['extension_Access'] === 'string' 
                ? currentAccount.idTokenClaims['extension_Access'] 
                : "";
        
        const products = claimAccess.includes(',') ? claimAccess.split(',') : [claimAccess];
        return products.map(product => product.trim().toLowerCase());
    }, [instance]);

    return getAccess;
}
