import { createContext } from "react";

export const EditMode = {
    DEFAULT: "default",
    REORDER: "reorder",
    DELETE: "delete",
};

export const RubricContext = createContext({
    rubric: null,
    setRubric: (_r) => { },
    rubricUrl: null,
    setRubricUrl: (_url) => { },
    editMode: EditMode.DEFAULT,
    setEditMode: (_e) => { },
    isEnhanced: true,
    waitingForMarksUpdate: false,
    setWaitingForMarksUpdate: (_m) => { }
});