import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { RubricType, Tab } from "src/api/goodpoint/Const";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import useFetchRubricQuestions from "src/api/goodpoint/useFetchRubric";
import RubricStructure from "src/components/content/exam/rubric-structure/RubricStructure";
import LoadingTab from "src/components/ui/LoadingTab";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { EditMode, RubricContext } from "src/pages/goodpoint/exam/tabs/rubric/RubricContext";
import useCallApi from "src/api/useCallApi";
import { Archive, EmptyPage, Page, PageStar } from "iconoir-react";
import ResetTabButton from "src/pages/goodpoint/exam/components/ResetTabButton";
import slugify from "slugify";
import { DownloadsMenuButton } from "src/pages/goodpoint/exam/components/DownloadsMenu";


function RubricDownloads() {
    const { examObject } = useContext(ExamContext);
    const examName = examObject.name ?? "this exam";
    const callApi = useCallApi();
    const [sourceFiles, setSourceFiles] = useState(null);

    function sl(text) {
        return `${slugify(text, { lower: true, strict: true, replacement: "_" })}`;
    }

    useEffect(() => {
        if (sourceFiles) return;
        callApi("GET", GoodPointApi.Rubric.Config(examObject.id))
            ?.then(
                (response) => {
                    if (response.ok) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const sfs = data["config"]["source_files"];
                            setSourceFiles(sfs);
                        })
                    }
                }
            )
    }, [callApi, examObject.id, sourceFiles]);
    
    return <DownloadsMenuButton
        text="Rubric Downloads"
        downloadsIcon={Page}
        rowData={[
            {
                Icon: EmptyPage,
                text: "Original rubric",
                endpointFunc: (filename) => GoodPointApi.Rubric.Original.Download(examObject.id, filename),
                filenameFunc: (filename) => filename,
                enabledClassName: "bg-zanista-orange-light",
                selectOptions: sourceFiles,
            },
            (
                (sourceFiles?.length > 1)
                ? {
                    Icon: EmptyPage,
                    text: "Combined original rubrics",
                    endpointFunc: () => GoodPointApi.Rubric.Combined.Download(examObject.id),
                    filenameFunc: () => `${sl(examName)}_rubric_processed.pdf`,
                    enabledClassName: "bg-zanista-orange-light",
                    selectOptions: sourceFiles,
                }
                : null
            ),
            {
                Icon: Page,
                text: "Processed rubric",
                endpointFunc: () => GoodPointApi.Rubric.Download(examObject.id, RubricType.PROCESSED),
                filenameFunc: () => `${sl(examName)}_rubric_processed.pdf`,
                enabledClassName: "bg-zanista-orange-mid",
            },
            {
                Icon: PageStar,
                text: "Enhanced rubric",
                endpointFunc: () => GoodPointApi.Rubric.Download(examObject.id, RubricType.ENHANCED),
                filenameFunc: () => `${sl(examName)}_rubric_enhanced.pdf`,
                enabledClassName: "bg-zanista-orange",
            },
            {
                Icon: Archive,
                text: "Rubric output (.zip)",
                endpointFunc: () => GoodPointApi.Rubric.Output.Download(examObject.id),
                filenameFunc: () => `${sl(examName)}_rubric_output.zip`,
                enabledClassName: "bg-zanista-orange",
            }
        ]}
    >
        <p>Click on a button below to download a specific version of the rubric for {examName}.</p>
        <p>Or, click on "Download Rubric Output" to download everything generated from the rubric files.</p>
    </DownloadsMenuButton>
}

function RubricTypeButton({
    Icon,
    text,
    rubricType,
    setRubricType,
    buttonRubricType, 
}) {
    return (
        <button
            className={
                `btn-clickable p-2 hover:scale-105 border-mid rounded-small w-full flex flex-row gap-2 justify-center ` +
                `${(rubricType === buttonRubricType) ? "bg-orange-mid font-weight-bold" : "bg-yellow-light"}`
            }
            onClick={() => setRubricType(buttonRubricType)}
        >
            {
                rubricType === buttonRubricType
                    ? <><Icon stroke="3.0"/><b>{text}</b></>
                    : <><Icon stroke="1.0"/><p>{text}</p></>
            }
        </button>
    )
}


export default function RubricCompleted() {
    const { examObject, setFooterControls } = useContext(ExamContext);
    const fetchRubric = useFetchRubricQuestions(examObject.id);
    const callApi = useCallApi();

    const [rubric, setRubric] = useState(null);
    const [rubricType, setRubricType] = useState(RubricType.ENHANCED);
    const [rubricUrl, setRubricUrl] = useState(null);
    const [editMode, setEditMode] = useState(EditMode.DEFAULT);
    const [waitingForMarksUpdate, setWaitingForMarksUpdate] = useState(false);

    useLayoutEffect(() => {
        setFooterControls([<RubricDownloads />]);
    }, [setFooterControls, examObject.id]);

    useEffect(() => {
        if (rubric) return;
        let fetchedRubric = fetchRubric();
        if (fetchedRubric) {
            if (fetchedRubric["rubric"]) {
                const newRubric = fetchedRubric["rubric"].sort((q1, q2) => q1["index"] - q2["index"]);
                setRubric(newRubric);
            }
        }
    }, [fetchRubric, rubric]);

    useEffect(() => {
        if (rubricUrl) return;
        callApi("GET", GoodPointApi.Rubric.Link(examObject.id))
            ?.then(response => {
                if (response.status === 200) {
                    response.json().then(body => {
                        var url = body["data"]["rubric_url"];
                        setRubricUrl(url);
                    })
                }
            })
    }, [callApi, examObject.id, rubricUrl]);

    return (
        <RubricContext.Provider value={{
            rubric, setRubric, 
            rubricUrl, setRubricUrl,
            editMode, setEditMode, 
            isEnhanced: (rubricType === RubricType.ENHANCED),
            waitingForMarksUpdate, setWaitingForMarksUpdate
        }}>
            <div className="flex flex-col gap-4">
                {
                    rubric &&
                    <div className="flex flex-row gap-4 w-full">
                        <RubricTypeButton 
                            Icon={PageStar} text={"Enhanced with AI"} 
                            rubricType={rubricType} setRubricType={setRubricType} buttonRubricType={RubricType.ENHANCED}
                        />
                        <RubricTypeButton 
                            Icon={EmptyPage} text={"Original"} 
                            rubricType={rubricType} setRubricType={setRubricType} buttonRubricType={RubricType.ORIGINAL}
                        />
                    </div>
                }
                {rubric ? <RubricStructure /> : <LoadingTab />}
                <div className="flex flex-row justify-between">
                    <ResetTabButton tab={Tab.RUBRIC} />
                </div>
            </div>
        </RubricContext.Provider>
    )
}
