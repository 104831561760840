import { CheckSquareSolid, Square } from "iconoir-react";
import { useState } from "react";


export default function IconCheckbox({
    defaultChecked = false,
    disabled = false,
    onClick = undefined,
    onCheck = undefined,
    onUncheck = undefined
}) {
    const [isChecked, setIsChecked] = useState(defaultChecked);

    return (
        <div className={`${disabled ? "pointer-events-none" : "cursor-pointer"}`}
            onClick={() => {
                if (disabled) return;
                onClick && onClick();
                setIsChecked(ic => {
                    const newIsChecked = !ic;
                    if (newIsChecked && onCheck) {
                        onCheck();
                    } else if (!newIsChecked && onUncheck) {
                        onUncheck();
                    }
                    return newIsChecked;
                });
            }}
        >
            {
                isChecked
                    ? <CheckSquareSolid color="var(--zanista-orange-dark)"/>
                    : <Square color="var(--zanista-orange-dark)"/>
            }
        </div>
    )
}