import { Skeleton, Stack } from "@mui/material";
import { ArrowRight, FastArrowDown, FastArrowRight, NavArrowDown, NavArrowUp, Xmark } from "iconoir-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { GoodpointGptModel, StepState } from "src/api/goodpoint/Const";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useCallApi from "src/api/useCallApi";
import Divider from "src/components/layout/Divider";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import GradeBoundaries from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";


const _generosity_map = {
    0: "Strictly adhere to the rubric",
    1: "Partial marks awarded for minor variations",
    2: "Partial marks awarded for closely aligning grading",
    3: "Lenient, rewarding the presence of key concepts",
    4: "Generous, allowing general alignment with rubric",
    5: "Highly generous, allowing large deviations",
}


function ConfigTable({ config, updateConfig, setLoadingComponents }) {
    const [configOpen, setConfigOpen] = useState(true);
    const { examState } = useContext(ExamContext);
    const [advancedOpened, setAdvancedOpened] = useState(false);

    function handleTokenLengthInput(e) {
        const inputvalue = e.target.value;
        if (/^\d*$/.test(inputvalue)) {
            updateConfig({"feedback_token_length": inputvalue});
            e.target.value = inputvalue;
        } else {
            e.target.value = config["feedback_token_length"];
        }
    }

    return (
        !(config && examState.grading >= StepState.READY)
            ? <TableSkeleton />
            : (
            <table className="table-auto border-separate border-spacing-2 w-full">
            <tbody>
                <tr className="font-normal p-0 m-0">
                    <td colSpan={2} className="w-full cursor-pointer p-0 m-0" onClick={() => setConfigOpen((c) => !c)}>
                        <div className="flex-row w-full align-center justify-between m-0 p-0 gap-0">
                            <h2 className="text-lg"><b>Options</b></h2>
                            {
                                configOpen
                                    ? <NavArrowDown/>
                                    : <NavArrowUp/>
                            }
                        </div>
                    </td>
                </tr>
                {
                    !configOpen 
                        ? null
                        : <>
                        <tr>
                            <td className="whitespace-nowrap">Marking Generosity</td>
                            <td><select 
                                className={
                                    `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                    `border-zanista-grey-mid transition hover:border-black`
                                }
                                defaultValue={config["generosity"]}
                                onBlur={(e) => updateConfig({"generosity": e.target.value})}
                            >
                                {
                                    Object
                                        .entries(_generosity_map)
                                        .map(([v, text], i) => <option key={i} value={v}>{v}: {text}</option>)
                                }
                            </select></td>
                        </tr>
                        <tr>
                            <td className="whitespace-nowrap">
                                Grade Boundaries
                            </td>
                            <td style={{"maxWidth": "100px"}}>
                                <div className=
                                    "mt-3 ml-[1px] outline outline-1 outline-gray-400 rounded-sm inline-block"
                                >
                                    <GradeBoundaries
                                        gradeBoundaries={config["grade_boundaries"]}
                                        setGradeBoundaries={(gb) => updateConfig({ "grade_boundaries": gb })}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={5}>
                                <div 
                                    className=
                                        "mt-2 mb-2 flex-row gap-small text-zanista-orange align-center cursor-pointer"
                                    onClick={() => setAdvancedOpened((a) => !a)}
                                >
                                    {
                                        advancedOpened
                                            ? <FastArrowDown color="var(--zanista-orange-dark)" width={"1em"} />
                                            : <FastArrowRight color="var(--zanista-orange-dark)" width={"1em"} />
                                    }
                                    <b className="user-select-none">Advanced</b>
                                    <div className="w-full ml-[5px] right-0 border-t border-zanista-orange-mid"></div>
                                </div>
                            </td>
                        </tr>
                        {
                            advancedOpened
                                ? <>
                                    <tr>
                                        <td>GPT Model</td>
                                        <td><select
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            defaultValue={config["gpt_model"]}
                                            onBlur={(e) => updateConfig({"gpt_model": e.target.value})}
                                        >
                                            {
                                                Object
                                                    .entries(GoodpointGptModel)
                                                    .map(([k, v]) => <option value={k}>{v}</option>)
                                            }
                                        </select></td>
                                    </tr>
                                    <tr>
                                        <td>Feedback token length</td>
                                        <td><input
                                            className={
                                                `w-full px-[10px] py-[14px] rounded-[4px] border ` + 
                                                `border-zanista-grey-mid transition hover:border-black`
                                            }
                                            type="number"
                                            onInput={handleTokenLengthInput}
                                            onBlur={(e) => {
                                                const v = Math.max(1, parseInt(e.target.value || "1"))
                                                e.target.value = `${v}`;
                                                updateConfig({"feedback_token_length": v});
                                            }}
                                            value={config["feedback_token_length"]}
                                        ></input></td>
                                    </tr>
                                </>
                                : null
                        }
                    </>
                }
            </tbody>
            </table>
        )
    )
}


function TableSkeleton() {
    return (
        <Stack className="mt-1 px-4 w-full">
            <Skeleton variant="text" sx={{ fontSize: '4rem' }} />

            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
            <div className="grid grid-cols-3 gap-4 -mt-4">
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
            </div>
        </Stack>
    )
}


function ProcessButton({ autoRun }) {
    const { examObject, examState, updateExamState } = useContext(ExamContext);
    const callApi = useCallApi();
    const [starting, setStarting] = useState(false);
    const enabled = useMemo(
        () => (examState.rubric === StepState.COMPLETED) && (examState.answers === StepState.COMPLETED),
        [examState.rubric, examState.answers]
    );

    const onButtonClick = useCallback(() => {
        setStarting(true);
        callApi("POST", GoodPointApi.Grading.Process(examObject.id))
            ?.then((response) => {
                if (response.status === 200) {
                    response.json().then((body) => {
                        const data = body["data"];
                        const newState = data["state"];
                        updateExamState({ grading: newState });
                    })
                }
            });
    }, [callApi, examObject.id, updateExamState]);

    return (
        <button 
            className={
                `flex flex-row gap-2 p-2 pl-3 rounded-md items-center ` +
                ` ${starting ? "bg-zanista-red" : enabled ? "bg-zanista-orange" : "bg-slate-300"}` +
                ` transition-all duration-300 ease-out` +
                ` ${(!enabled || starting) ? "disabled" : "hover:bg-zanista-red btn-clickable"}` +
                ` ${enabled ? "text-black" : "text-gray-500"}`
            }
            onClick={onButtonClick}
            disabled={!enabled || starting}
        >
            <b>{autoRun ? "Waiting for answers" : enabled ? "Begin Processing" : "Not available"}</b>
            {
                autoRun
                    ? <TailSpin strokeWidth={3.0} color="gray" width={20} height={20} wrapperClass="padding-small" />
                    :
                !enabled
                    ? <Xmark width={20} height={20} strokeWidth={2.0}/>
                    :
                starting
                    ? <TailSpin strokeWidth={3.0} color="black" width={20} height={20} wrapperClass="padding-small" />
                    : <ArrowRight width={20} strokeWidth={2.0}/>
            }
        </button>
    )
}

function ConfigAutoRun({ config, updateConfig }) {
    return (
        config &&
        <div className="flex flex-row w-full px-2 gap-2 items-center justify-end text-right">
            <p>Auto run?</p>
            <input 
                className=
                    "mt-2 mb-2 p-5 w-6 h-6 accent-zanista-orange border border-zanista-grey-mid"
                type="checkbox"
                checked={config["auto_run"]}
                onChange={(e) => updateConfig({"auto_run": e.target.checked})}
            ></input>
        </div>
    )
}

export default function GradingReady() {
    const callApi = useCallApi();
    const { examObject, examState } = useContext(ExamContext);
    const [config, setConfig] = useState(null);
    const [needReload, setNeedReload] = useState(true);
    const [loadingComponents, setLoadingComponents] = useState({
        configTable: true
    });
    const autoRunAllowed = useMemo(() => {
        return (examState.rubric < StepState.COMPLETED) || (examState.answers < StepState.COMPLETED)
    }, [examState.answers, examState.rubric]);

    useEffect(() => {
        if (!needReload) return;
        setConfig(null);
        callApi("GET", GoodPointApi.Grading.Config(examObject.id))
            ?.then(
                (response) => {
                    if (response.status === 200) {
                        response.json().then((body) => {
                            const data = body["data"];
                            const config = data["config"];
                            setConfig(config);
                        })
                        setNeedReload(false);
                        setLoadingComponents((lc) => { return {...lc, configTable: false }});
                    }
                }
            )
    }, [callApi, examObject.id, needReload, setLoadingComponents]);

    const updateConfig = useCallback((updates) => {
        setConfig((c) => {
            const newConfig = {...c, ...updates};
            callApi("POST", GoodPointApi.Grading.Config(examObject.id), { body: {"config": newConfig} });
            return newConfig;
        });
    }, [callApi, examObject.id]);

    return (
        <div className="centered">
            <div className="w-3/4">
                <ConfigTable config={config} updateConfig={updateConfig} setLoadingComponents={setLoadingComponents} />
                <Divider/>
                {
                    Object.values(loadingComponents).every(v => v === false)
                        ? <div className="space-y-3">
                            <div className="flex-row mt-4 align-center w-full justify-between px-2">
                                <p>Credits cost: <b>[PLACEHOLDER]</b></p>
                                <ProcessButton autoRun={autoRunAllowed && config?.auto_run}/>
                            </div>
                            {
                                autoRunAllowed &&
                                <ConfigAutoRun config={config} updateConfig={updateConfig}/>
                            }
                        </div>
                        : <Stack className="mt-1 px-4 w-full">
                            <div className="grid grid-cols-2 gap-4 -mt-4">
                                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                                <Skeleton variant="text" sx={{ fontSize: '3rem' }} />
                            </div>
                        </Stack>
                }
            </div>
        </div>
    );
}