import LoadingTab from "src/components/ui/LoadingTab";
import { useContext, useMemo } from "react";
import { ExamStructure } from "src/components/content/exam/ExamStructure";
import { StudentsContext } from "src/pages/goodpoint/exam/tabs/answers/StudentsContext";
import StudentFeedbackItem from "src/components/content/exam/grading-structure/student-feedback/StudentFeedbackItem";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import useSendEdit from "src/api/goodpoint/useSendEdit";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";


export default function StudentFeedbackStructure({
    studentFeedback,
    isFilterOnlyMistakes = false,
    isFilterOnlyFlagged = false
}) {
    const { examObject } = useContext(ExamContext);
    const { selectedStudent, answerPdfUrl } = useContext(StudentsContext);
    const { sendEdit, isSending } = useSendEdit(GoodPointApi.Grading.Edit.Student(examObject.id, selectedStudent?.id));

    const numAnswers = useMemo(() => {
        return Object.keys(studentFeedback ?? {}).length;
    }, [studentFeedback]);

    function Header() {
        return (
            <div className="flex-row align-center gap-large">
                <p>{numAnswers} Question{(numAnswers > 1) ? "s" : ""} Answered</p>
            </div>
        )
    }

    if (!studentFeedback) {
        return <LoadingTab />
    }

    return (
        <ExamStructure
            structureInit={studentFeedback}
            saveEdit={sendEdit}
            isSavingEdit={isSending}
            HeaderComponent={Header}
            ItemComponent={StudentFeedbackItem}
            initiallyExpanded={false}
            itemCommonProps={{ isFilterOnlyMistakes, isFilterOnlyFlagged }}
            listDisplay={[0, 0, 1, 1, 1, 1]}
            fileUrl={answerPdfUrl}
        />
    )
}