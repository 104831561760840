import React from 'react';
import { Link } from 'react-router-dom';
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';

function AdminPage() {
    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Dashboard" href="/dashboard" />
                <Title title="Admin Portal" />
                <div className="flex-col gap-4">
                    <AdminSection title="General">
                        <AdminSectionLink title="User Management" href="/admin/users" />
                    </AdminSection>
                    <AdminSection title="NewsWitch">
                        <AdminSectionLink title="Pipeline Tracker" href="/newswitch/pipeline-tracker" />
                        <AdminSectionLink title="Ticker Management" href="/admin/ticker-management" />
                    </AdminSection>
                    <AdminSection title="PaperPal">
                        <AdminSectionLink title="Blog Uploads" href="/paperpal/uploads" />
                    </AdminSection>
                </div>
            </div>
        </div>
    );
}

function AdminSection({ title, children }) {
    return (
        <div className="flex-col gap-4">
            <div className="flex-col gap-2">
                <h3>{title}</h3>
                <hr />
            </div>
            {children}
        </div>
    );
}

function AdminSectionLink({ title, href }) {
    return (
        <Link to={href} className='btn-clickable p-2 flex-row gap-4 bg-zanista-orange-mid rounded-small hover:scale-105 hover:bg-zanista-orange-mid'>
            <h3>{title}</h3>
        </Link>
    );
}

export default AdminPage;