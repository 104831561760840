import useGetRole from "./useGetRole";

// Returns whether a user has access to a product (see useGetAccess.js)
function useHasRole(role) {
    const getRole = useGetRole();
    return getRole() === role.toLowerCase();
}

function useCallHasRole() {
    const getRole = useGetRole();
    return (role) => getRole() === role.toLowerCase();
}

export { useHasRole, useCallHasRole };
