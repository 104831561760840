import { useCallback } from "react";
import useNewsWitchApi from "src/api/newswitch/useNewsWitchApi";

export default function useSendChat() {
  const callApi = useNewsWitchApi();

  const sendChatMessage = useCallback(async (messages) => {
    try {
      const response = await callApi("POST", "/chat", {
        body: {
          messages: messages
        }
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.result || "No response received";
      } else {
        console.error("Chat API error:", response);
        return "Error communicating with chatbot";
      }
    } catch (error) {
      console.error("Network or server error:", error);
      return "Server error. Please try again later.";
    }
  }, [callApi]);

  return sendChatMessage;
}
