export const ExamStep = ({
    CONFIGURE_EXAM: "Configure exam",
    UPLOAD_RUBRIC: "Upload rubric",
    PROCESS_RUBRIC: "Process rubric",
    UPLOAD_STUDENT_ANSWERS: "Upload student answers",
    PROCESS_STUDENT_ANSWERS: "Process student answers",
    READY_TO_GRADE: "Ready to grade",
    GRADED: "Graded"
})

export const Tab = ({
    INFO: "info",
    RUBRIC: "rubric",
    ANSWERS: "student answers",
    GRADING: "grading"
});

export const TabState = ({
    COMPLETE: "complete",
    CURRENT: "current",
    NEXT: "next",
    INCOMPLETE: "incomplete"
})

export const StepState = ({
    NOT_AVAILABLE: 0,
    AVAILABLE: 1,
    READY: 2,
    PROCESSING: 3,
    COMPLETED: 4,
    FAILED: 5
})

export const StepError = ({
    INSUFFICIENT_CREDITS: "Insufficient Credits"
})

export const LogType = ({
    INFO: "info",
    ERROR: "error",
    SUCCESS: "success",
    FAIL: "fail",
    CLOSE: "close"
})

export const RubricType = ({
    ORIGINAL: "original",
    COMBINED: "combined",
    PROCESSED: "processed",
    ENHANCED: "enhanced"
})

export const GradingType = ({
    CLASS: "class",
    STUDENTS: "students"
})

export const GradingImages = ({
    // Class
    OVERALL_QUESTION_BOXPLOT: "overall_question_boxplot",
    STUDENTS_DISTRIBUTION: "students_distribution",
    // Student
    QUESTION_MARKS_POLAR: "question_marks_polar",
    STUDENT_QUANTILE: "student_quantile"
})

export const GoodpointVisionModel = ({
    "o1": "OpenAI: o1",
    "gpt-4o": "OpenAI: GPT-4o",
    "gpt-4-turbo-preview-azure-vision": "Azure: GPT-4 Turbo Vision (Preview)",
    "gpt-4-vision-preview": "OpenAI: GPT-4 Vision (Preview)",
})

export const GoodpointGptModel = ({
    "o3-mini-high": "OpenAI: o3 Mini (High Effort)",
    "o3-mini-med": "OpenAI: o3 Mini (Med Effort)",
    "o3-mini-low": "OpenAI: o3 Mini (Low Effort)",
    "o1": "OpenAI: o1",
    "o1-preview": "OpenAI: o1 (Preview)",
    "o1-mini": "OpenAI: o1 Mini",
    "gpt-4o": "OpenAI: GPT-4o",
    "gpt-4-turbo": "OpenAI: GPT-4 Turbo",
    "gpt-4-turbo-preview-azure": "Azure: GPT-4 Turbo (Preview)",
    "gpt-4-turbo-preview": "OpenAI: GPT-4 Turbo (Preview)",
    "gpt-4-1106-preview": "OpenAI: GPT-4 1106 (Preview)",
    "gpt-4-32k": "OpenAI: GPT-4 32k",
    "gpt-4-8k": "OpenAI: GPT-4 8k",
})


export const EditAction = ({
    MODIFY: "modify",
    MARKS: "marks",
    STEP_MARKS: "step_marks",
    // does not have an equivalent on the backend, used only for replacing structure items with updated versions
    REPLACE: "replace",
    NEW: "new",
    REORDER: "reorder",
    DELETE: "delete",
})