import Divider from "src/components/layout/Divider";

import "./StructureSection.css";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { NavArrowDown, NavArrowUp, EditPencil } from "iconoir-react";
import { useState, useEffect, useContext, useCallback } from "react";
import RichMathEditor from "src/components/ui/RichMathEditor";
import { tokenizeText } from "src/components/content/exam/StructureUtil";
import "mathlive";
import RenderMath from "src/components/ui/RenderMath";
import { useHasRole } from "src/api/useHasRole";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { EditAction } from "src/api/goodpoint/Const";
import HoverIconButton from "src/components/ui/HoverIconButton";


export function SectionHeading({ heading, style = {} }) {
    return (
        <p className={`font-italic user-select-none font-size-large`} style={style}>
            {heading}
        </p>
    );
}

export function AiGenerated() {
    return (
        <p
            className={`ai-generated fg-orange-dark font-size-medium user-select-none`}
        >
            (Generated with AI)
        </p>
    );
}

export default function StructureSection({
    heading = "",
    content = "",
    editKeys = undefined,
    headingControls = null,
    aiGenerated = false,
    hide = false,
    children = undefined,
    includeDivider = true
}) {
    const { saveEdit, isSavingEdit, editStructure } = useContext(ExamStructureContext);
    const { isExpanded, toggle, expand, collapse } = useExpandCollapse(true);

    //collapse the question initially
    useEffect(() => {
        if (hide) {
            collapse();
        }
    }, [collapse, hide])

    const [isEdit, setIsEdit] = useState(false);
    const readOnly = useHasRole("student");

    const onClickSave = useCallback((newContent) => {
        const editAction = {"action": EditAction.MODIFY, "keys": editKeys, "value": newContent};
        saveEdit([editAction], () => { editStructure(editAction); setIsEdit(false); });
    }, [editKeys, editStructure, saveEdit]);

    const tokens = tokenizeText(content);

    return (
        <div className="structure-section flex-col gap-small overflow-auto">
            <div className="flex-row justify-between gap-small align-center">
                <div
                    className="flex-row cursor-pointer fill-width align-center justify-content-space-between"
                    onClick={toggle}
                >
                    <div className="flex-row justify-between gap-mid align-baseline">
                        <SectionHeading heading={heading} />
                        {aiGenerated && <AiGenerated />}
                    </div>
                </div>
                {/* edit pencil */}
                {!isEdit && !readOnly && heading !== "Step-by-Step" && heading !== "Statistics" && !hide && (
                    <HoverIconButton
                        onClick={() => { setIsEdit(true); expand(); }}
                        Icon={EditPencil}
                        tooltip="Edit content"
                        tooltipPlacement="left"
                        strokeWidth={1.5}
                    />
                )}

                {isExpanded && (
                    <div className="section-heading-controls align-center">{headingControls}</div>
                )}

                <div className="cursor-pointer align-center" onClick={toggle}>
                    {isExpanded ? <NavArrowDown /> : <NavArrowUp />}
                </div>
            </div>

            {
                isExpanded &&
                !isEdit &&
                (
                    heading === "Step-by-Step" || heading === "Statistics" ? (
                        children
                    ) : (
                        <div>
                            <RenderMath tokens={tokens} />
                        </div>
                    )
                )
            }

            {
                isExpanded &&
                isEdit &&
                heading !== "Step-by-Step" &&
                heading !== "Statistics" && (
                    <div>
                        <RichMathEditor
                            // initialContent={content}
                            initialTokens={tokens}
                            isSaving={isSavingEdit}
                            saveContent={onClickSave}
                            closeEditor={() => setIsEdit(false)}
                        />
                    </div>
                )
            }
            {
                includeDivider &&
                <Divider className="mt-2"/>
            }
        </div >
    );
}
