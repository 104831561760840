import "./GradingCompleted.css"
import { Archive, EmptyPage, Group, MultiplePages, StatsUpSquare } from "iconoir-react";
import { GradingType, } from "src/api/goodpoint/Const";
import { useCallback, useContext, useEffect, useLayoutEffect, useState } from "react";
import ClassGrading from "src/pages/goodpoint/exam/tabs/grading/class-grading/ClassGrading";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";
import useCallApi from "src/api/useCallApi";
import GoodPointApi from "src/api/goodpoint/GoodPointApi";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import StudentGrading from "src/pages/goodpoint/exam/tabs/grading/student-grading/StudentGrading";
import { DownloadsMenuButton } from "src/pages/goodpoint/exam/components/DownloadsMenu";
import { standardSlugify } from "src/util/StringUtil";


export default function GradingCompleted() {
    const callApi = useCallApi();
    const { examObject, setFooterControls } = useContext(ExamContext);
    const [gradingType, setGradingType] = useState(GradingType.CLASS);
    const [examResults, setExamResults] = useState(null);
    const [gradeBoundaries, setGradeBoundaries] = useState(null);
    const [clickedStudent, setClickedStudent] = useState(null);
    
    useLayoutEffect(() => {
        setFooterControls([<GradingDownloads />]);
    }, [examObject.id, setFooterControls]);
    
    const updateGradeBoundaries = useCallback((gb) => {
        setGradeBoundaries((_) => {
            callApi("POST", GoodPointApi.Grading.Config(examObject.id), { 
                body: {"config": {"grade_boundaries": gb}}
            });
            return gb;
        });
    }, [callApi, examObject.id]);

    useEffect(() => {
        if (examResults) return;
        callApi("GET", GoodPointApi.Grading.Results.Class(examObject.id))
            ?.then((response) => {
                if (response.ok) {
                    response.json().then(body => {
                        const data = body["data"];
                        setExamResults(data);
                    })
                }
            });
    }, [callApi, examObject.id, examResults]);

    useEffect(() => {
        if (gradeBoundaries) return;
        callApi("GET", GoodPointApi.Grading.Config(examObject.id))
            ?.then((response) => {
                if (response.ok) {
                    response.json().then(body => {
                        const config = body["data"]["config"];
                        setGradeBoundaries(config["grade_boundaries"]);
                    })
                }
            });
    }, [callApi, examObject.id, gradeBoundaries]);

    return (
        <div className="grading-content">
            <div className='grading-type-select gap-large fill-width'>
                <TypeSelectButton
                    gradingType={GradingType.CLASS} Icon={MultiplePages} text={"Class Performance"}
                    selectedGradingType={gradingType} setSelectedGradingType={setGradingType}
                />
                <TypeSelectButton
                    gradingType={GradingType.STUDENTS} Icon={Group} text={"Student Performance"}
                    selectedGradingType={gradingType} setSelectedGradingType={setGradingType}
                />
            </div>
            <GradingContext.Provider value={{
                gradingType, setGradingType,
                examResults, setExamResults,
                gradeBoundaries, setGradeBoundaries: updateGradeBoundaries,
                clickedStudent, setClickedStudent
            }}>
                {
                    gradingType === GradingType.CLASS
                        ? <ClassGrading />
                        : <StudentGrading />
                }
            </GradingContext.Provider>
        </div>
    )
}

function TypeSelectButton({ gradingType, Icon, text, selectedGradingType, setSelectedGradingType }) {
    return (
        <button
            className={
                `flex flex-row justify-center gap-2 btn-clickable rounded-md p-2 hover:scale-105 ` +
                `${(selectedGradingType === gradingType) ? "bg-zanista-orange-mid" : "bg-zanista-yellow-light"}`
            }
            onClick={() => setSelectedGradingType(gradingType)}
        >
            <Icon strokeWidth={1.5} />
            {
                (selectedGradingType === gradingType) ? <b>{text}</b> : <p>{text}</p>
            }
        </button>
    )
}

function GradingDownloads() {
    const callApi = useCallApi();
    const { examObject } = useContext(ExamContext);
    const examName = examObject.name ?? "this exam";
    
    // don't have access to students context here. TODO: find a better way to get a students list just for downloads
    const [students, setStudents] = useState(null);
    useEffect(() => {
        if (students) return;
        callApi("GET", GoodPointApi.Answers.Students(examObject.id))
            ?.then(response => {
                if (response.ok) {
                    response.json().then(body => {
                        const s = body["data"]["students"];
                        setStudents(s);
                    })
                }
            })
    }, [callApi, examObject.id, students]);

    return (
        <DownloadsMenuButton
            text="Grading Downloads"
            downloadsIcon={StatsUpSquare}
            rowData={[
                {
                    Icon: MultiplePages,
                    text: "Class feedback",
                    endpointFunc: () => GoodPointApi.Grading.Feedback.Class.Download(examObject.id),
                    filenameFunc: () => `${standardSlugify(examName)}_class_feedback.pdf`,
                    enabledClassName: "bg-zanista-orange-mid"
                },
                {
                    Icon: Group,
                    text: "Student feedback",
                    endpointFunc: 
                        (studentId) => GoodPointApi.Grading.Feedback.Student.Download(examObject.id, studentId),
                    filenameFunc: 
                        (studentId) => {
                            return `${standardSlugify(students?.[studentId]?.name)}_feedback_${standardSlugify(examName)}.pdf`
                        },
                    enabledClassName: "bg-zanista-orange-mid",
                    selectOptions: students && Object.values(students).map((s) => [s.id, s["name"]])
                },
                {
                    Icon: ExcelIcon,
                    text: "Question Results Spreadsheet (.xlsx)",
                    endpointFunc: () => GoodPointApi.Grading.Feedback.Class.Download(examObject.id, "xlsx"),
                    filenameFunc: () => `${standardSlugify(examName)}_class_feedback.xlsx`,
                    enabledClassName: "bg-zanista-yellow-light"
                },
                {
                    Icon: ExcelIcon,
                    text: "Student Results Spreadsheet (.xlsx)",
                    endpointFunc: 
                        (studentId) => GoodPointApi.Grading.Feedback.Student.Download(examObject.id, studentId, "xlsx"),
                    filenameFunc: 
                        (studentId) => {
                            return `${standardSlugify(students?.[studentId]?.name)}_feedback_${standardSlugify(examName)}.xlsx`
                        },
                    enabledClassName: "bg-zanista-yellow-light",
                    selectOptions: students && Object.values(students).map((s) => [s.id, s["name"]])
                },
                {
                    Icon: Archive,
                    text: "Grading output (.zip)",
                    endpointFunc: () => GoodPointApi.Grading.Output.Download(examObject.id),
                    filenameFunc: () => `${standardSlugify(examName)}_grading_output.zip`,
                    enabledClassName: "bg-zanista-orange",
                }
            ]}
        >
            <p>Click on a button below to download the feedback for the whole class or for a specific student.</p>
            <p>Or, click on "Grading Output" to download a .zip archive of all the grading output.</p>
        </DownloadsMenuButton>
    )
}


function ExcelIcon(props) {
    return (
        <div className="relative">
            <EmptyPage {...props}/>
            <span className={
                "absolute text-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 " +
                "pointer-events-none select-none"
            }>x</span>
        </div>
    )
}