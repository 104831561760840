import React, { useState } from 'react';
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import useDeleteTicker from 'src/api/newswitch/useDeleteTicker';
import TickerSearchBar from '../newswitch/components/TickerSearchBar';

export default function TickerDeletePage() {

    const deleteTicker = useDeleteTicker();

    const [tickerInfo, setTickerInfo] = useState(null);
    const [deleting, setDeleting] = useState(false);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Admin Portal" href="/admin" />
                <Title title="Ticker Management">
                    <p>Add or delete a ticker from the database.</p>
                </Title>
                <div className="flex-col gap-8">
                    <div className='w-full sm:w-1/2'>
                        <TickerSearchBar 
                            onSelect={(tickerInfo) => setTickerInfo(tickerInfo)}
                            maxNameLength={10}
                            showAddCustomTicker={true}
                            onCustomTickerSelect={(ticker, exchange) => {
                                setTickerInfo({
                                    ticker: ticker,
                                    name: null,
                                    exchange: exchange
                                });
                            }}
                        />
                    </div>
                    {
                        tickerInfo &&
                        <div className="flex-col gap-2">
                            <p>Ticker: {tickerInfo.ticker}</p>
                            {tickerInfo.name && <p>Name: {tickerInfo.name}</p>}
                            <p>Exchange: {tickerInfo.exchange}</p>
                        </div>
                    }
                    {
                        tickerInfo &&
                        <button 
                            className='btn-clickable p-2 flex-row gap-4 bg-zanista-orange-mid rounded-small hover:scale-105 hover:bg-zanista-orange-mid w-min'
                            disabled={deleting}
                            onClick={() => {
                                setDeleting(true);
                                if (window.confirm(`Warning: This action is irreversible. Are you sure you want to delete ${tickerInfo.ticker} from the database?`)) {
                                    deleteTicker(tickerInfo.ticker).then(({success, message}) => {
                                        setDeleting(false);
                                        if (success) {
                                            setTickerInfo(null);
                                            alert("Ticker deleted successfully");
                                            window.location.reload();
                                        } else {
                                            alert(message);
                                        }
                                    });
                                }
                            }}
                        >
                            Delete
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}
