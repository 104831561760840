import { Eye, EyeClosed, EyeSolid, NavArrowDown, NavArrowRight, Prohibition, Xmark } from "iconoir-react";
import { useContext, useEffect, useMemo } from "react";
import { ExpandCollapseContext } from "src/components/layout/expand-collapse/ExpandCollapseContext";
import useExpandCollapse from "src/components/layout/expand-collapse/useExpandCollapse";
import { ExamStructureContext } from "src/components/content/exam/ExamStructureContext";
import { SectionHeading } from "src/components/content/exam/StructureSection";
import { ExamContext, EditMode } from "src/pages/goodpoint/exam/ExamContext";
import { Visibility, VisibilityContext } from "src/components/content/exam/Visibility";
import IconCheckbox from "src/components/ui/IconCheckbox";


const NESTING_LEVEL_STYLES = [
    "padding-mid bg-orange-light border-mid",
    "padding-mid bg-yellow-light border-mid",
    "padding-small bg-grey-light border-thin"
];

const VISIBILITY_STYLES = {
    [Visibility.HIDDEN]: [[EyeClosed, "gray"], "bg-grey-light outline-transparent"],
    [Visibility.PARTIALLY_HIDDEN]: [[EyeSolid, "gray"], "bg-grey-light outline-transparent"],
    [Visibility.PARTIALLY_VISIBLE]: [[EyeSolid, "var(--zanista-orange-dark)"], "bg-orange-light outline-transparent"],
    [Visibility.VISIBLE]: [[Eye, "black"], "bg-yellow-light outline-black"],
}

function ItemVisibleButton({ enabled = true, visibility, toggleIsVisible }) {
    var style;
    if (enabled) {
        style = VISIBILITY_STYLES[visibility];
    } else {
        style = [[Prohibition, "var(--zanista-red)"], "bg-red-light outline-transparent"];
    }
    const Icon = style[0][0];
    const iconColour = style[0][1];
    return (
        <div
            className={
                `btn-clickable rounded-md align-center p-1 cursor-pointer ` +
                `${style[1]} ${enabled ? "" : "pointer-events-none translucent"}`
            }
            onClick={() => toggleIsVisible()}
        >
            { 
                // @ts-ignore
                <Icon color={iconColour} /> 
            }
        </div>
    )
}

export function DefaultHeader({ itemData, indexHierarchy, nestingLevel: _nestingLevel }) {
    return itemData.name ?? "ITEM";
}

export function StructureSubQuestions({ itemData, indexHierarchy, editKeysHierarchy, ...props }) {
    const { ItemComponent, isSelectionMode } = useContext(ExamStructureContext);
    const { clear: clearVisibility, countHiddenChildren } = useContext(VisibilityContext);

    const hiddenChildCount = countHiddenChildren(indexHierarchy);

    return <div className="flex-col gap-mid">
        {
            (indexHierarchy.length === 0) &&
            itemData.sub_items &&
            itemData.sub_items.length > 0 &&
                <SectionHeading heading={"Sub Questions"} />
        }
        {
            (hiddenChildCount > 0) && 
            (!isSelectionMode) &&
            <div
                className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer"
                onClick={() => clearVisibility(indexHierarchy)}
            >
                <p>{hiddenChildCount} hidden (Clear)</p>
                <Xmark strokeWidth={1.0} />
            </div>
        }
        {
            itemData.sub_items &&
            itemData.sub_items.map((subData) => {
                return <ItemComponent
                    key={subData.id}
                    itemData={subData}
                    indexHierarchy={indexHierarchy}
                    editKeysHierarchy={[...editKeysHierarchy, "sub_items"]}
                    {...props}
                />
            })
        }
    </div>
}

export function StructureItem({
    itemData,
    indexHierarchy = [],
    editKeysHierarchy = [],
    HeaderComponent = DefaultHeader,
    ContentComponent = null,
    SubItemsComponent = StructureSubQuestions,
    editMode = EditMode.DEFAULT,
}) {
    const indexList = useMemo(() => [...indexHierarchy, itemData.index], [indexHierarchy, itemData.index]);
    const editKeys = useMemo(() => [...editKeysHierarchy, itemData.index], [editKeysHierarchy, itemData.index]);

    const {
        isExpanded: isAllExpanded,
        isCollapsed: isAllCollapsed,
        reset: resetParentExpand
    } = useContext(ExpandCollapseContext);

    const { isExpanded, expand, collapse, toggle } = useExpandCollapse();
    const { setDeleteSelected } = useContext(ExamContext);

    useEffect(() => { isAllExpanded && expand(); }, [isAllExpanded, expand]);
    useEffect(() => { isAllCollapsed && collapse(); }, [isAllCollapsed, collapse]);

    const { isSelectionMode, searchVisibility, searchTerms, clearSearch } = useContext(ExamStructureContext);
    const { getVisibility, setVisibility } = useContext(VisibilityContext);
    const { 
        getVisibility: getSearchVisibility, 
        setVisibility: setSearchVisibility, 
        getChildrenVisibility: getChildrenSearchVisibility 
    } = searchVisibility;

    const tagList = useMemo(() => [...indexHierarchy, itemData.index], [itemData.index, indexHierarchy]);
    const tagListText = useMemo(() => tagList.join("."), [tagList]);
    const visibility = getVisibility(tagList);
    const visibilityFromSearch = getSearchVisibility(tagList);
    const show = [visibility, visibilityFromSearch].filter(v => [Visibility.PARTIALLY_VISIBLE, Visibility.VISIBLE].includes(v)).length === 2;

    const handleCheckboxChange = (isChecked) => {
        if (isChecked) {
            setDeleteSelected((prevArray) => [...prevArray, indexHierarchy]);
        } else {
            setDeleteSelected((prevArray) =>
                prevArray.filter((item) => item !== indexHierarchy)
            );
        }
    };

    useEffect(() => {
        if (!searchTerms || searchTerms.length === 0) return;
        let newSearchVisibility = Visibility.HIDDEN;
        const tagListTextLower = tagListText.toLowerCase();
        if (searchTerms.findIndex(s => tagListTextLower.startsWith(s.toLowerCase())) !== -1) {
            newSearchVisibility = Visibility.VISIBLE;
        }

        if (![Visibility.VISIBLE, Visibility.PARTIALLY_VISIBLE].includes(newSearchVisibility)) {
            for (const val of Object.values(itemData)) {
                if ([Visibility.VISIBLE, Visibility.PARTIALLY_VISIBLE].includes(newSearchVisibility)) {
                    break;
                }
                const type = typeof val;
                if (type === "string") {
                    for (const searchTerm of searchTerms) {
                        if (val.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                            newSearchVisibility = Visibility.VISIBLE;
                            break;
                        }
                    }
                }
            }
        }
        if (newSearchVisibility === visibilityFromSearch) return;
        if (newSearchVisibility === Visibility.HIDDEN) {
            const childrenSearchVisibility = getChildrenSearchVisibility(tagList);
            const numChildrenSearchVisible = Object
                .values(childrenSearchVisibility)
                .filter(v => [Visibility.VISIBLE, Visibility.PARTIALLY_VISIBLE].includes(v))
                .length;
            if (numChildrenSearchVisible > 0) {
                return
            }
        }
        setSearchVisibility(tagList, newSearchVisibility);
    }, [getChildrenSearchVisibility, itemData, searchTerms, setSearchVisibility, tagList, tagListText, visibilityFromSearch]);

    return (
        <div className={`${(isSelectionMode || show) ? "" : "display-none"}`}>
            <div
                className={`space-y-2`}
            >
                {
                    editMode !== EditMode.REORDER &&
                    <>
                        <div className="flex flex-row gap-3 align-center">
                            {
                                editMode === EditMode.DELETE &&
                                <IconCheckbox
                                    onCheck={() => handleCheckboxChange(true)}
                                    onUncheck={() => handleCheckboxChange(false)}
                                />
                            }
                            {
                                isSelectionMode
                                    ? <ItemVisibleButton
                                        enabled={
                                            [Visibility.VISIBLE, Visibility.PARTIALLY_VISIBLE]
                                            .includes(visibilityFromSearch)
                                        }
                                        visibility={visibility}
                                        toggleIsVisible={
                                            () => (visibility < Visibility.VISIBLE)
                                                ? setVisibility(tagList, Visibility.VISIBLE)
                                                : setVisibility(tagList, Visibility.HIDDEN)
                                        }
                                    />
                                    : null
                            }
                            {
                                [Visibility.PARTIALLY_HIDDEN, Visibility.HIDDEN].includes(visibilityFromSearch) &&
                                <div
                                    className="clear-hidden fg-orange-dark flex-row align-center cursor-pointer text-nowrap"
                                    onClick={clearSearch}
                                >
                                    <p className="text-nowrap">Clear search</p>
                                    <Xmark strokeWidth={1.0} />
                                </div>
                            }
                            <button
                                onClick={() => { resetParentExpand(); toggle(); }}
                                className={
                                    `${(!show) ? "translucent" : ""} btn-clickable rounded-small ` + 
                                    `flex flex-row w-full gap-1 hover:scale-[1.02] hover:py-3 ` +
                                    `${
                                        NESTING_LEVEL_STYLES[
                                            Math.min(indexList.length - 1, NESTING_LEVEL_STYLES.length - 1)
                                        ]
                                    }`
                                }
                            >
                                {
                                    isExpanded 
                                        ? <NavArrowDown width="1em" strokeWidth={2.5}/> 
                                        : <NavArrowRight width="1em" strokeWidth={2.5}/>
                                }
                                <HeaderComponent 
                                    itemData={itemData} 
                                    indexHierarchy={indexHierarchy}
                                    nestingLevel={indexList.length - 1} 
                                />
                            </button>
                        </div>
                        {
                            <div className={`${isExpanded ? "" : "display-none"}`}>
                                <div className={`${(!show) ? "translucent" : ""}`}>
                                    {
                                        ContentComponent &&
                                        <ContentComponent
                                            itemData={itemData}
                                            nestingLevel={indexList.length - 1}
                                            indexHierarchy={indexHierarchy}
                                            editKeysHierarchy={editKeys}
                                        />
                                    }
                                </div>
                                {
                                    SubItemsComponent &&
                                    <SubItemsComponent
                                        itemData={itemData}
                                        indexHierarchy={indexList}
                                        editKeysHierarchy={editKeys}
                                    />
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}