import { useCallback, useState } from "react";
import useCallApi from "src/api/useCallApi";
import { protectedResources } from "src/auth/AuthConfig";


export default function useSendEdit(endpoint) {
    const callApi = useCallApi({
        msalRequest: { scopes: protectedResources.apiGoodPoint.scopes.write }
    });
    const [isSending, setIsSending] = useState(false);

    const sendEdit = useCallback((actions, onSuccess = undefined, onFail = undefined) => {
        return callApi("PUT", endpoint, { body: actions })
            ?.then(response => {
                setIsSending(false);
                if (response.ok) {
                    if (onSuccess) {
                        response.json().then(body => {
                            const result = body["data"];
                            onSuccess(result);
                        });
                    }
                } else {
                    console.error(`Failed to put edit: (${response.status}) ${response.statusText}`);
                    onFail && onFail();
                }
            });
    }, [callApi, endpoint]);

    return { sendEdit, isSending };
}