// import { Link } from "react-router-dom";
// If you still want to use the ContactPopup, import it here.
// Otherwise, remove references to it as needed.

// import { ContactPopup } from "src/pages/home/components/ContactPopup";

function Pricing() {
    return (
        <div>
            <HeaderSection />
            <PricingCards />
            <FooterNote />
        </div>
    );
}

function HeaderSection() {
    return (
        <div className="flex-col justify-start items-start pb-6 px-2">
            <h2 className="afacad-flux-normal pt-6 font-bold text-3xl kanit-regular">
                Our Products &amp; Pricing
            </h2>
        </div>
    );
}

function PricingCards() {
    return (
        <div className="flex-col justify-start items-start pb-4 px-2">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 w-full">

                {/* PaperPal */}
                <PricingCard
                    title="PaperPal"
                    descriptions={[
                        "The first fully automated AI-powered research assistant (ARA), designed to revolutionize how you access and review academic literature.",
                        "Price: 25 USD for monthly updates/alerts featuring newly published papers each month on your chosen topic.",
                        "Comprehensive literature review report on any specific topic – $500 USD. Includes all top papers related to your query, complete with metadata, from any selected year or date range."
                    ]}
                />

                {/* GoodPoint */}
                <PricingCard
                    title="GoodPoint"
                    descriptions={[
                        "The first fully automated AI grader (ATA) for exams and assessments, transforming how educators evaluate and manage student performance.",
                        "Pricing: 1 USD per student per exam, plus a fixed cost of 10 USD per exam. ",

                        "For schools or exam boards, contact us for annual contract pricing."
                    ]}
                />

                {/* NewsWitch */}
                <PricingCard
                    title="NewsWitch"
                    descriptions={[
                        "The first fully automated AI quantitative and fundamental analysis assistant, delivering unparalleled insights into market trends.",
                        "Pricing: 25 USD per month (subscription).",
                        "Features: Gain access to sentiment analysis for over 40,000 stocks and AI-driven quantitative and fundamental market insights.",
                        "Metadata, priced at 500 USD, include sentiment analysis, company monitoring, and comprehensive coverage of both financial and non-financial entity news for any specified time period."
                    ]}
                />

                {/* If you have additional products, add more <PricingCard /> as needed */}
            </div>
        </div>
    );
}

function PricingCard({ title, descriptions }) {
    return (
        <div className="flex-col w-full gap-y-4 pb-6 rounded shadow-lg p-4 rounded-tl-3xl rounded-br-3xl bg-white transform transition-transform duration-200 hover:scale-105">
            <h3 className="afacad-flux-normal font-bold text-xl sm:text-2xl text-accent mb-2">
                {title}
            </h3>
            {descriptions.map((paragraph, idx) => (
                <p key={idx} className="afacad-flux-normal text-xs sm:text-sm text-gray-600 mb-2">
                    {paragraph}
                </p>
            ))}
            {/* If you want to add a “Contact us” link or popup here, you can. */}
            {/* Example:
            <p className="afacad-flux-normal text-xs sm:text-sm text-gray-600">
                Please{" "}
                <ContactPopup
                    placeholder={`I would like more information about ${title} pricing.`}
                >
                    <span className="font-bold hover:underline hover:text-accent cursor-pointer">
                        contact us
                    </span>
                </ContactPopup>
                {" "}for more details.
            </p> */}
        </div>
    );
}

function FooterNote() {
    return (
        <div className="px-2 pb-6">
          <p className="afacad-flux-normal text-sm sm:text-base text-gray-700">
            Let our cutting-edge AI solutions support your research, assessment,
            and financial analysis needs. {" "}
            <a
              href="https://calendly.com/arman-khaledian-zanista/30min?month=2025-01"
              target="_blank"
              rel="noopener noreferrer"
              className="afacad-flux-normal text-accent hover:text-gray-800 text-md sm:text-lg mb-2 hover:underline cursor-pointer"
            >
              Set up a quick call
            </a>{" "}
            for tailored solutions or more details!
          </p>
        </div>
    );
}

export default Pricing;
