import { FastArrowDown, FastArrowUp } from "iconoir-react";
import { useContext } from "react";
import { StudentsSelect } from "src/pages/goodpoint/exam/components/StudentsSelect";
import { calcGrade } from "src/pages/goodpoint/exam/tabs/grading/GradeBoundaries";
import GradingContext from "src/pages/goodpoint/exam/tabs/grading/GradingContext";


export default function GradingStudentsList({
    examResults,
    orderBy,
    setOrderBy,
    isAscendingOrder,
    setIsAscendingOrder,
}) {
    const { gradeBoundaries } = useContext(GradingContext);

    const maxMarks = examResults?.max_marks;

    return (
        <div className="grading-students-list flex-col gap-mid fill-width">
            <div className="flex-row justify-content-space-between align-center">
                <h2>Students</h2>
                <div className="feedback-order-by flex-row align-center margin-small">
                    <p>Order by:</p>
                    <select
                        className="order-by-select padding-x-small font-size-large margin-small"
                        value={orderBy}
                        onChange={(event) => setOrderBy(event.target.value)}
                    >
                        <option value="grade">Grade</option>
                        <option value="name">Name</option>
                    </select>
                    <div
                        className="cursor-pointer"
                        onClick={() => setIsAscendingOrder(!isAscendingOrder)}
                    >
                        {isAscendingOrder ? (
                            <FastArrowUp color="var(--zanista-orange-dark)" />
                        ) : (
                            <FastArrowDown color="var(--zanista-orange-dark)" />
                        )}
                    </div>
                </div>
            </div>
            <StudentsSelect 
                extraCells={[
                    (studentData) =>
                        <p className="text-right">{studentData["total_marks"]}/{maxMarks} Marks</p>,
                    (studentData) => {
                        if (!maxMarks) return null;
                        const percentage = 100.0 * studentData["total_marks"] / maxMarks;
                        return <p className="text-left">({percentage.toFixed(1)}%)</p>
                    },
                    (studentData) => {
                        if (!maxMarks) return null;
                        const percentage = 100.0 * studentData["total_marks"] / maxMarks;
                        const grade = calcGrade(percentage, gradeBoundaries)
                        return <p className="text-left">Grade <b>{grade}</b></p>
                    },
                ]}
            />
        </div>
    );
}
