import { useEffect, useState, useContext } from "react";
import { StepState } from "src/api/goodpoint/Const";
import { ExamContext } from "src/pages/goodpoint/exam/ExamContext";
import AnswersAvailable from "src/pages/goodpoint/exam/tabs/answers/AnswersAvailable";
import AnswersReady from "src/pages/goodpoint/exam/tabs/answers/AnswersReady";
import AnswersCompleted from "src/pages/goodpoint/exam/tabs/answers/AnswersCompleted";
import AnswersProcessing from "src/pages/goodpoint/exam/tabs/answers/AnswersProcessing";


export default function AnswersTab() {
    const { examState, updateExamState } = useContext(ExamContext);
    const [subtitle, setSubtitle] = useState(null);
    const [answersContent, setAnswersContent] = useState(null);

    useEffect(() => {
        switch (examState.answers) {
            case StepState.AVAILABLE:
                setSubtitle("Upload the student answers to get started");
                setAnswersContent(<AnswersAvailable />);
                break;
            case StepState.READY:
                setSubtitle("Configure the student answer processing");
                setAnswersContent(<AnswersReady />);
                break;
            case StepState.PROCESSING:
                setSubtitle("Student answers are being processed...");
                setAnswersContent(<AnswersProcessing />);
                break;
            case StepState.COMPLETED:
                setSubtitle("Overview of the student answers");
                setAnswersContent(<AnswersCompleted />);
                break;
            default:
                setAnswersContent(null);
                break;
        }
    }, [examState.answers, examState.rubric, updateExamState]);

    useEffect(() => {
        if (examState.answers === StepState.NOT_AVAILABLE) {
            if (examState.rubric >= StepState.READY) {
                updateExamState({answers: StepState.AVAILABLE});
            }
        }
    }, [examState.answers, examState.rubric, updateExamState]);

    return (
        <div className="answers-tab">
            <div className="flex flex-row justify-between items-center mb-4">
                <h2 className="text-xl">Student Answers</h2>
                <p>{subtitle}</p>
            </div>
            {answersContent}
        </div>
    );
}
