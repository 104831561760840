import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import BackLink from 'src/components/nav/BackLink';
import Title from 'src/components/content/Title';
import useCallApi from 'src/api/useCallApi';
import { protectedResources } from 'src/auth/AuthConfig';
import { TailSpin } from 'react-loader-spinner';
import Popup from 'reactjs-popup';
import { capitalize } from 'src/util/StringUtil';
import Tooltip from '@mui/material/Tooltip';
import countryList from 'react-select-country-list'

const products = [{
    "name": "GoodPoint",
    "value": "goodpoint"
}, {
    "name": "NewsWitch",
    "value": "newswitch"
}, {
    "name": "PaperPal",
    "value": "paperpal"
}, {
    "name": "Ochre",
    "value": "ochre"
}];

function UserManagementPage() {

    const callAPI = useCallApi();

    const [tempUserEmail, setTempUserEmail] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [searchHistory, setSearchHistory] = useState([]);
    const [timestamp, setTimestamp] = useState(0);
    const [userOverview, setUserOverview] = useState(undefined);

    const searchBoxRef = useRef(null);

    useEffect(() => {
        callAPI("GET", protectedResources.apiManagement.endpoint + "/user-overview")?.then((response) => {
            if (response.status !== 200) {
                return;
            }
            response.json().then(response => 
                setUserOverview(response)
            );
        });
    }, [callAPI]);

    useEffect(() => {
        if (userEmail === "") {
            searchBoxRef.current.value = "";
        }
    }, [userEmail]);

    return (
        <div className="centered">
            <div className="container">
                <BackLink prevPage="Admin Portal" href="/admin" />
                <Title title="User Management" align="end">
                    <div className="flex-col gap-0 items-end">
                        <Popup 
                            trigger={
                                <button 
                                    className="w-fit p-2 py-1 mb-3 text-sm rounded shadow-md bg-[var(--zanista-orange-light)] hover:bg-[var(--zanista-orange-mid)] cursor-pointer"
                                >
                                    Create New User
                                </button>
                            }
                            nested modal
                        >
                            {
                                // @ts-ignore
                                close => (
                                    <CreateUserPopup close={close} setUserEmail={setUserEmail} />
                                )
                            }
                        </Popup>
                        <Popup 
                            trigger={
                                <button 
                                    className="w-fit p-2 py-1 mb-3 text-sm rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer"
                                >
                                    Set Product Access
                                </button>
                            }
                            nested modal
                        >
                            {
                                // @ts-ignore
                                close => (
                                    <SetDefaultAccessPopup close={close} />
                                )
                            }
                        </Popup>
                    </div>
                </Title>
                <div className="flex-col gap-3">
                    <div className='flex-row gap-2'>
                        <input 
                            type="text" 
                            ref={searchBoxRef}
                            placeholder="Search by email" 
                            className="p-2 rounded shadow-md outline outline-1 bg-white w-full" 
                            onChange={(e) => setTempUserEmail(e.target.value)}
                        />
                        {
                            // dropdown of search history
                            searchHistory.length > 0 &&
                            <select 
                                className="p-2 rounded outline outline-1 bg-white"
                                onChange={(e) => {
                                    if (e.target.value === "") return;
                                    setTempUserEmail(e.target.value);
                                    searchBoxRef.current.value = e.target.value;
                                }}
                            >
                                <option value="">Search History</option>
                                {
                                    searchHistory.map((email, index) => {
                                        return <option key={index} value={email}>{email}</option>
                                    })
                                }
                            </select>
                        }
                        <button 
                            className="p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer"
                            onClick={() => {
                                    setUserEmail(tempUserEmail)
                                    if (!searchHistory.includes(tempUserEmail)) {
                                        setSearchHistory([tempUserEmail, ...searchHistory]);
                                    }
                                    setTimestamp(Date.now())
                                }
                            }
                        >
                            Search
                        </button>
                    </div>
                    { userOverview ?
                        <div className='flex-row justify-between'>
                            <p className='font-bold'>
                                Active Accounts: {userOverview["user_count"]} (EU: {userOverview["eu_user_count"]} US: {userOverview["us_user_count"]})
                            </p>
                            <p>
                                Accounts with Access: {userOverview["accounts_with_access"]}
                            </p>
                            <p>
                                Deleted Accounts: {userOverview["deleted_users"]}
                            </p>
                        </div>
                        : <p>Loading user overview...</p>
                    }
                    <hr className="w-full" />
                    {
                        userEmail !== "" &&
                            <UserInfoSection email={userEmail} ts={timestamp} setUserEmail={setUserEmail}/>
                    }
                    <NewUsers setUserEmail={setUserEmail} />
                    <AccessLists setUserEmail={setUserEmail} />
                    <AdminList setUserEmail={setUserEmail} />
                </div>
            </div>
        </div>
    );
}

function UserInfoSection({ email = undefined, ts, setUserEmail }) {

    const callAPI = useCallApi();

    const [isLoading, setIsLoading] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (ts < 0) return;
        if (!email || email === "" || !email.includes("@")) return;
        setIsLoading(true);
        setUpdateSuccess(undefined);
        callAPI("GET", protectedResources.apiManagement.endpoint + `/user?email=${email}`)?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to fetch user data: " + response.status + " " + response.statusText);
                setUserData(null)
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => {
                    let user = response['user'];
                    // Convert comma separated string to array
                    if (user.access === null) {
                        user.access = []
                    } else {
                        user.access = user.access.includes(",") ? user.access.split(",") : [user.access];
                    }
                    setUserData(user);
                });
                setIsLoading(false);
            }
        });
    }, [callAPI, email, ts]);

    const changeAccess = (access) => {
        setIsLoading(true);
        setUpdateSuccess(false);
        callAPI("POST", protectedResources.apiManagement.endpoint + "/user/update", {
            body: {
                user_id: userData.id,
                access: access.join(",")
            }
        })?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to update user: " + response.status + " " + response.statusText);
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => {
                    let user = response["user"];
                    // Convert comma separated string to array
                    if (user.access === null) {
                        user.access = []
                    } else {
                        user.access = user.access.includes(",") ? user.access.split(",") : [user.access];
                    }                    
                    setUserData(user);
                });
                setIsLoading(false);
            }
        });
    }

    const changeRole = (role) => {
        setIsLoading(true);
        setUpdateSuccess(false);
        callAPI("POST", protectedResources.apiManagement.endpoint + "/user/update", {
            body: {
                user_id: userData.id,
                role: role
            }
        })?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to update user: " + response.status + " " + response.statusText);
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => {
                    let user = response["user"];
                    // Convert comma separated string to array
                    if (user.access === null) {
                        user.access = []
                    } else {
                        user.access = user.access.includes(",") ? user.access.split(",") : [user.access];
                    }                    
                    setUserData(user);
                });
                setIsLoading(false);
            }
        });
    }

    const deleteUser = () => {
        if (window.confirm("Are you sure you want to delete this user?") === false) return;
        setIsLoading(true);
        setUpdateSuccess(false);
        callAPI("DELETE", protectedResources.apiManagement.endpoint + "/user", {
            body: {
                user_id: userData.id
            }
        })?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to delete user: " + response.status + " " + response.statusText);
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                setUserData(null);
                setUserEmail("");
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        if (isLoading) return;
        if (updateSuccess === undefined) return;
        setUpdateSuccess(errorMessage === undefined);
    }, [isLoading, errorMessage, updateSuccess]);

    return (
            isLoading ? 
                <div className="flex-row gap-2">
                    <TailSpin strokeWidth={3.0} color="black" height={20} width={20} />
                    <p>{updateSuccess !== undefined ? "Updating user data..." : "Loading user data..."}</p>
                </div>
            : (
                userData ?
                <div className="flex-col gap-1">
                {updateSuccess && <p className="text-sm text-gray-500">Note: The user may need to log out and log back in to see the changes.</p>}
                {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
                <div className={"flex-col gap-4 p-2 rounded outline outline-1" + (updateSuccess ? " outline-green-500" : "") + (errorMessage ? " outline-red-500" : "")}>
                    {/* User Info */}
                    <div className="flex-row justify-between items-start gap-2">
                        <div className="flex-col gap-1">
                            <p className='text-lg'>{userData.first_name} {userData.last_name}</p>
                            <p className='text-gray-500'>{userData.email}</p>
                            <p className='text-xs mt-1'>Created {new Date(userData.created_at * 1000).toLocaleString()}</p>
                        </div>
                        <div className="flex-col gap-1 flex-grow justify-between">
                            <p className='text-xs text-gray-500 text-end'>ID: {userData.id}</p>
                        </div>
                    </div>
                    {/* Location */}
                    <hr className='w-full' />
                    <div className="flex-col gap-1">
                        <p className='text-sm'>Location: {userData.location}</p>
                        <p className='text-sm'>Data Region: {userData.data_region}</p>
                    </div>
                    {/* User Management */}
                    <hr className='w-full' />
                    <div className="flex-col gap-1">
                        <p className='text-sm'>Role: {userData.role}</p>
                        <p className='text-sm'>Access: {userData.access.join(", ")}</p>
                    </div>
                    {/* GoodPoint */}
                    <hr className='w-full' />
                    <div className="flex-col gap-1">
                        <p className='text-sm'>GoodPoint Credits: {userData.wallet.credits}</p>
                    </div>
                    {/* Action Buttons */}
                    <hr className='w-full' />
                    <div className="flex-row gap-4">
                        <button 
                            className="p-2 rounded shadow-md bg-[var(--zanista-red-mid)] hover:bg-[var(--zanista-red)] cursor-pointer"
                            onClick={deleteUser}
                        >
                            Delete User
                        </button>
                        <Popup 
                            trigger={<button className="p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer">Modify Access</button>}
                            nested modal
                        >
                            {
                                // @ts-ignore
                                close => (
                                    <GiveAccessPopup userData={userData} changeAccess={changeAccess} close={close}/>
                                )
                            }
                        </Popup>
                        <Popup 
                            trigger={
                                <button 
                                    className="p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer"
                                >
                                    Modify Role
                                </button>
                            }
                            nested modal
                        >
                            {
                                // @ts-ignore
                                close => (
                                    <ChangeRolePopup userData={userData} changeRole={changeRole} close={close}/>
                                )
                            }
                        </Popup>
                        <button 
                            className="p-2 rounded shadow-md bg-blue-200 hover:bg-blue-300 cursor-pointer"
                            onClick={() => {
                                setUserEmail("");
                            }}
                        >
                            Done
                        </button>
                    </div>
                </div>
                </div>
            : <p>{errorMessage}</p>
        )
    );
}

// @ts-ignore
function GiveAccessPopup({ close, userData, changeAccess }) {
    const [selectedProducts, setSelectedProducts] = useState(userData.access?.toString().split(",").map(access => access.trim()));

    return (
        <div className='flex-col p-4 text-sm bg-white rounded gap-3 min-w-64 max-w-2/3'>
            <h2>Modify Access</h2>
            <hr className='w-full' />
            <div className="flex-col gap-1">
                <p>{userData.first_name} {userData.last_name}</p>
                <p className='text-xs text-gray-500'>{userData.email}</p>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
                {
                    products.map((product) => {
                        return (
                            <label className="flex items-center">
                                <input
                                    type="checkbox" 
                                    name="product-access"
                                    value={product.value}
                                    checked={selectedProducts.includes(product.value)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedProducts([...selectedProducts, product.value]);
                                        } else {
                                            setSelectedProducts(selectedProducts.filter(p => p !== product.value));
                                        }
                                    }}
                                    className="cursor-pointer mr-2"
                                />
                                {product.name}
                            </label>
                        )
                    })
                }
            </div>
            <div className='flex-row gap-2 mt-2'>
                <button className='p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer'
                    onClick={() => {
                        close();
                    }}
                >
                    Cancel
                </button>
                <button className='p-2 rounded shadow-md bg-[var(--zanista-red-mid)] hover:bg-[var(--zanista-red)] cursor-pointer'
                     onClick={() => {
                        changeAccess(selectedProducts);
                        close();
                    }}
                >
                    Confirm
                </button>
            </div>
        </div>
    );
}

// @ts-ignore
function ChangeRolePopup({ close, userData, changeRole }) {
    const [selectedRole, setSelectedRole] = useState(userData.role);

    const roles = ["admin", "user"];

    return (
        <div className='flex-col p-4 text-sm bg-white rounded gap-3 min-w-64 max-w-2/3'>
            <h2>Change User Role</h2>
            <hr className='w-full' />
            <div className="flex-col gap-1">
                <p>{userData.first_name} {userData.last_name}</p>
                <p className='text-xs text-gray-500'>{userData.email}</p>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-2">
                {
                    roles.map((product) => {
                        return (
                            <label className="flex items-center">
                                <input
                                    type="checkbox" 
                                    name="product-access"
                                    value={product}
                                    checked={selectedRole === product}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedRole(product);
                                        } else {
                                            setSelectedRole("user");
                                        }
                                    }}
                                    className="cursor-pointer mr-2"
                                />
                                {capitalize(product)}
                            </label>
                        )
                    })
                }
            </div>
            <div className='flex-row gap-2 mt-2'>
                <button className='p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer'
                    onClick={() => {
                        close();
                    }}
                >
                    Cancel
                </button>
                <button className='p-2 rounded shadow-md bg-[var(--zanista-red-mid)] hover:bg-[var(--zanista-red)] cursor-pointer'
                     onClick={() => {
                        changeRole(selectedRole);
                        close();
                    }}
                >
                    Confirm
                </button>
            </div>
        </div>
    );
}

// @ts-ignore
function NewUsers({ setUserEmail = (userEmail) => {} }) {

    const callAPI = useCallApi();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        callAPI("GET", protectedResources.apiManagement.endpoint + `/new-users?` + new URLSearchParams({
            max_users: "10"
        }).toString())?.then((response) => {
            if (response.status !== 200) {
                setErrorMessage("Failed to fetch user data: " + response.status + " " + response.statusText);
                setUsers([])
                setIsLoading(false);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => setUsers(response['users']));
                setIsLoading(false);
            }
        });
    }, [callAPI]);

    return (
        <div className="flex-col gap-2">
            <h3 className="text-lg font-bold">New Users</h3>
            <hr className="w-full" />
            <div className="flex-col gap-2 rounded-md">
                <div className="grid grid-cols-3 gap-2 p-2 py-1 shadow-md bg-zanista-orange-mid rounded-md">
                    <p>Created at</p>
                    <p>Name</p>
                    <p>Email</p>
                </div>
                <div className="p-2 overflow-y-auto h-56">
                    {
                        isLoading ? 
                        <div className="flex-row gap-2">
                            <TailSpin strokeWidth={3.0} color="black" height={20} width={20} />
                            <p>Loading users...</p>
                        </div>
                        : (
                            users ?
                            <div className="flex-col gap-2">
                                    {
                                        users.map((user) => {
                                                return <NewUserCard key={user["id"]} user={user} onClick={() => setUserEmail(user["email"])} />;
                                            })
                                    }
                            </div>
                            : <p>{errorMessage}</p>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

function NewUserCard({ user, onClick }) {
    return (
        <div className="grid grid-cols-3 gap-2 bg-[var(--zanista-orange-light)] p-2 py-1 rounded-md shadow-md cursor-pointer hover:bg-[var(--zanista-orange-mid)]" onClick={onClick}>
            <p>{new Date(user["created_at"] * 1000).toLocaleString()}</p>
            <p>{user["first_name"]} {user["last_name"]}</p>
            <p>{user["email"]}</p>
        </div>
    );
}

// @ts-ignore
function AccessLists({setUserEmail = (userEmail) => {}}) {

    const callAPI = useCallApi();

    const [errorMessage, setErrorMessage] = useState({});
    const [users, setUsers] = useState({});
    const [selectedProduct, setSelectedProduct] = useState(products[0]);

    const fetchAccessList = useCallback((product) => {
        callAPI("GET", protectedResources.apiManagement.endpoint + `/access-list?` + new URLSearchParams({
            product: product
        }).toString())?.then((response) => {
            if (response?.status !== 200) {
                setErrorMessage(prev => ({
                    ...prev,
                    [product]: "Failed to fetch user data: " + response.status + " " + response.statusText
                }));
                setUsers(prev => ({
                    ...prev,
                    [product]: []
                }));
            } else {
                setErrorMessage(prev => ({
                    ...prev,
                    [product]: undefined
                }));
                response.json().then(response => setUsers(prev => ({
                    ...prev,
                        [product]: response['users']
                    })));
                }
            }
        );
    }, [callAPI]);

    // Update the user dictionary with the access list for each product
    useEffect(() => {
        // Fetch data for all products on load
        Promise.all(products.map(product => fetchAccessList(product.value)));
    }, [fetchAccessList]);

    return (
        <div className="flex-col gap-2">
            <h3 className="text-lg font-bold">Accounts with Product Access</h3>
            <hr className="w-full" />
            <div className="flex-col gap-2">
                <div className="flex-row gap-1">
                    {products.map((product) => (
                        <button
                            key={product.value}
                            className={`px-4 py-2 rounded-t-md ${selectedProduct.value === product.value ? 'bg-zanista-orange-mid shadow-md' : 'bg-zanista-orange-light hover:bg-zanista-orange-mid'}`}
                            onClick={() => setSelectedProduct(product)}
                        >
                            {product.name}
                        </button>
                    ))}
                </div>
                <AccessList 
                    users={users[selectedProduct.value]} 
                    errorMessage={errorMessage[selectedProduct.value]} 
                    onClick={setUserEmail}
                />
            </div>
        </div>
    );
}

// @ts-ignore
function AccessList({ users, onClick = (userEmail) => {}, errorMessage = undefined }) {

    return (
        <div className="flex-col gap-2">
            <div className="flex-col gap-2 rounded-md">
                <div className="grid grid-cols-3 gap-2 p-2 py-1 shadow-md bg-zanista-orange-mid rounded-md">
                    <p>Name</p>
                    <p>Email</p>
                    <p>Access</p>
                </div>
                <div className="p-2 overflow-y-auto max-h-96">
                    {
                        users ?
                        <div className="flex-col gap-2">
                                {
                                    users.map((user) => {
                                            return <AccessListUserCard key={user["id"]} user={user} onClick={() => onClick(user["email"])} />;
                                        })
                                }
                        </div>
                        : (
                            errorMessage ?
                            <p className="text-red-500">{errorMessage}</p>
                            :
                            <div className="flex-row gap-2">
                                <TailSpin strokeWidth={3.0} color="black" height={20} width={20} />
                                <p>Loading users...</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

function AccessListUserCard({ user, onClick = () => {} }) {

    let accessList = user && user["access"] ? user["access"].toString().split(",").map(access => access.trim()) : [];
    const accessString = accessList.join(", ");

    return (
        <div className="grid grid-cols-3 gap-2 bg-[var(--zanista-orange-light)] px-2 py-1 text-sm rounded-md shadow-md cursor-pointer hover:bg-[var(--zanista-orange-mid)]" onClick={onClick}>
            <p>{user["first_name"]} {user["last_name"]}</p>
            <p>{user["email"]}</p>
            <p>{accessString}</p>
        </div>
    );
}

// @ts-ignore
function AdminList({setUserEmail = (userEmail) => {}}) {

    const callAPI = useCallApi();

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [users, setUsers] = useState([]);

    const fetchAccessList = useCallback(() => {
        callAPI("GET", protectedResources.apiManagement.endpoint + "/admin-list")?.then((response) => {
            if (response?.status !== 200) {
                setErrorMessage("Failed to fetch data: " + response.status + " " + response.statusText);
                setUsers([]);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => setUsers(response['users']));
            }
        });
    }, [callAPI]);

    // Update the user dictionary with the access list for each product
    useEffect(() => {
        fetchAccessList();
    }, [fetchAccessList]);

    return (
        <div className="flex-col gap-2">
            <h3 className="text-lg font-bold">Admin Accounts</h3>
            <hr className="w-full" />
            <div className="flex-col gap-2">
                <div className="flex-col gap-2">
                <div className="flex-col gap-2 rounded-md">
                    <div className="grid grid-cols-3 gap-2 p-2 py-1 shadow-md bg-zanista-orange-mid rounded-md">
                        <p>Name</p>
                        <p>Email</p>
                        <p>Access</p>
                    </div>
                    <div className="p-2 overflow-y-auto max-h-96">
                        {
                            users && users.length > 0 ?
                            <div className="flex-col gap-2">
                                    {
                                        users.map((user) => {
                                                return <AccessListUserCard key={user["id"]} user={user} onClick={() => setUserEmail(user["email"])} />;
                                            })
                                    }
                            </div>
                            : (
                                errorMessage ?
                                <p className="text-red-500">{errorMessage}</p>
                                :
                                <div className="flex-row gap-2">
                                    <TailSpin strokeWidth={3.0} color="black" height={20} width={20} />
                                    <p>Loading users...</p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}

// @ts-ignore
function CreateUserPopup({ close, setUserEmail = (userEmail) => {} }) {

    const callAPI = useCallApi();

    const countryOptions = useMemo(() => countryList().getData(), [])

    const [userInfo, setUserInfo] = useState({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        location: "United Kingdom",
        job_title: "",
        role: "user",
        access: [],
        require_password_reset: true,
    });

    const [keysWithErrors, setKeysWithErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const validateUserInfo = useCallback((userInfo) => {
        const errors = [];
        // Check that the email is valid
        if (!userInfo["email"].includes("@")) {
            errors.push("email");
        }
        // Check that email, password, first_name, last_name are not empty
        for (const key of ["email", "password", "first_name", "last_name"]) {
            if (userInfo[key] === "") {
                errors.push(key);
            }
        }
        setKeysWithErrors(errors);
        return errors.length === 0;
    }, []);

    const createUser = useCallback((userInfo) => {
        const infoToSend = {
            email: userInfo["email"],
            default_password: userInfo["password"],
            first_name: userInfo["first_name"],
            last_name: userInfo["last_name"],
            location: userInfo["location"],
            job_title: userInfo["job_title"] === "" ? null : userInfo["job_title"],
            // The following fields cannot be changed on creation for now
            role: "user",
            access: null,
            require_password_change: (userInfo["require_password_reset"].toString() === "true").toString(),
        }
        setErrorMessage(undefined);
        callAPI("POST", protectedResources.apiManagement.endpoint + "/user", {
            // Only send the fields that are not null
            body: Object.fromEntries(Object.entries(infoToSend).filter(([key, value]) => value !== null))
        })?.then((response) => {
            setIsLoading(false);
            if (response?.status !== 200) {
                const errorCode = response.status;
                const statusText = response.statusText;
                response.json().then(response => {
                    setErrorMessage("Failed to create user: " + errorCode + " - " + response["error"]);
                }).catch(error => {
                    setErrorMessage("Failed to create user: " + errorCode + (statusText ? " - " + statusText : " - Error"));
                });
            } else {
                response.json().then(response => {
                    setUserEmail(response["user"]["email"]);
                    close();
                });
            }
        });
    }, [callAPI, close, setUserEmail]);

    return (
        <div className='flex-col p-4 text-sm bg-white rounded gap-3 min-w-64 max-w-2/3'>
            <h2>Create a user account</h2>
            <hr className='w-full' />
            {
                isLoading &&
                <div className="flex-row gap-2">
                    <TailSpin strokeWidth={3.0} color="black" height={16} width={16} />
                    <p>Creating user...</p>
                </div>
            }
            {
                errorMessage && <p className="text-red-500">{errorMessage}</p>
            }
            <div className="flex-col">
                <p className="text-xs text-gray-500">A user's role and product access can be changed after account creation</p>
                <p className="text-xs text-gray-500">Default product access will not apply to accounts created here</p>
            </div>
            <div className="flex-col gap-2 mt-2">
                {
                    Object.entries(userInfo).map(([key, value]) => {
                        // For now, we don't want to allow the user to change access or role during account creation, but these can be added here later
                        if (key === "require_password_reset" || key === "access" || key === "role") {
                            return null;
                        }
                        const isDisabled = key === "role" || key === "access";
                        return (
                            <label className={"grid grid-cols-2 items-center " + (keysWithErrors.includes(key) && "text-red-500")}>
                                <p className="text-black">{key.split("_").map(word => capitalize(word)).join(" ")}</p>
                                <Tooltip 
                                    title={isDisabled ? "This field must be changed after account creation" : ""}
                                    placement="left"
                                    componentsProps={{
                                        tooltip: {
                                            sx: {
                                                backgroundColor: "var(--zanista-orange-light)",
                                                color: "black",
                                                maxWidth: "150px",
                                            }
                                        }
                                    }}
                                >
                                    {   key === "location" ?
                                        <select
                                            name={key}
                                            value={countryOptions.find(option => option.label === value)?.value}
                                            onChange={(e) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    [key]: e.target.value
                                                });
                                            }}
                                            disabled={isDisabled}
                                            className={"cursor-pointer mr-2 outline outline-1 rounded-sm px-1 bg-white " + (isDisabled && "text-gray-500")}
                                        >
                                            {countryOptions.map((option) => (
                                                <option value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                        :
                                        <input
                                            type="text" 
                                            name={key}
                                            value={value.toString()}
                                            onChange={(e) => {
                                                setUserInfo({
                                                    ...userInfo,
                                                    [key]: e.target.value
                                                });
                                                if (keysWithErrors.includes(key)) {
                                                    setKeysWithErrors(keysWithErrors.filter(k => k !== key));
                                                }
                                            }}
                                            disabled={isDisabled}
                                            className={"cursor-pointer mr-2 outline outline-1 rounded-sm px-1 " + (isDisabled && "text-gray-500")}
                                        />
                                    }
                                </Tooltip>
                            </label>
                        )
                    })
                }
                <label className="flex flex-row items-center gap-4">
                    <p>Require password reset</p>
                    <input
                        type="checkbox"
                        name="require_password_reset"
                        checked={userInfo["require_password_reset"].toString() === "true"}
                        onChange={(e) => {
                            setUserInfo({
                                ...userInfo,
                                require_password_reset: e.target.checked
                            });
                        }}
                    />
                </label>
            </div>
            <div className='flex-row gap-2 mt-2'>
                <button className='p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer'
                    onClick={() => {
                        close();
                    }}
                >
                    Cancel
                </button>
                <button className={'p-2 rounded shadow-md bg-[var(--zanista-red-mid)] hover:bg-[var(--zanista-red)] cursor-pointer ' + (isLoading && "bg-gray-200")}
                     onClick={() => {
                        if (!validateUserInfo(userInfo)) {
                            return;
                        }
                        setIsLoading(true);
                        createUser(userInfo);
                    }}
                    disabled={isLoading}
                >
                    Confirm
                </button>
            </div>
        </div>
    );
}

// @ts-ignore
function SetDefaultAccessPopup({ close }) {

    const callAPI = useCallApi();

    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const fetchProductsAcceptingNewUsers = useCallback(() => {
        setIsLoading(true);
        callAPI("GET", protectedResources.apiManagement.endpoint + "/products/accepting-users")?.then((response) => {
            setIsLoading(false);
            if (response?.status !== 200) {
                setErrorMessage("Failed to fetch data: " + response.status + " " + response.statusText);
                setSelectedProducts([]);
            } else {
                setErrorMessage(undefined);
                response.json().then(response => setSelectedProducts(response['products'].map(product => product.id)));
            }
        });
    }, [callAPI]);

    const setProductAcceptingNewUsers = useCallback((product, is_accepting) => {
        return callAPI("POST", protectedResources.apiManagement.endpoint + "/products/accepting-users", {
            body: {
                product_id: product,
                is_accepting_users: is_accepting
            }
        })?.then((response) => {
            if (response?.status !== 200) {
                setErrorMessage("Failed to update product: " + response.status + " " + response.statusText);
            } else {
                setErrorMessage(undefined);
            }
        });
    }, [callAPI]);

    const updateProductsAcceptingNewUsers = useCallback(() => {
        const promises = [];
        setIsLoading(true);
        products.forEach(product => {
            promises.push(setProductAcceptingNewUsers(product.value, selectedProducts.includes(product.value)));
        });
        Promise.all(promises).then(() => {
            setIsLoading(false);
            if (errorMessage !== undefined) {
                return;
            }
            close();
        });
    }, [selectedProducts, setProductAcceptingNewUsers, close, errorMessage]);

    useEffect(() => {
        fetchProductsAcceptingNewUsers();
    }, [fetchProductsAcceptingNewUsers]);

    return (
        <div className='flex-col p-4 text-sm bg-white rounded gap-3 min-w-64 max-w-2/3'>
            <h2>Set the products that a new user can automatically have access to</h2>
            <hr className='w-full' />
            {
                isLoading ?
                <div className="flex-row gap-2">
                    <TailSpin strokeWidth={3.0} color="gray" height={14} width={14} />
                    <p className="text-xs text-gray-500">Loading...</p>
                </div>
                :
                <p className="text-xs text-gray-500">Note: A user who clicks "Sign up to product X" will only be given access to product X.</p>
            }
            {
                errorMessage && <p className="text-red-500">{errorMessage}</p>
            }
            <div className="grid grid-cols-2 gap-2 mt-2">
                {
                    products.map((product) => {
                        return (
                            <label className={"flex items-center " + (isLoading && "text-gray-500")}>
                                <input
                                    type="checkbox" 
                                    name="product-access"
                                    value={product.value}
                                    checked={selectedProducts.includes(product.value)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedProducts([...selectedProducts, product.value]);
                                        } else {
                                            setSelectedProducts(selectedProducts.filter(p => p !== product.value));
                                        }
                                    }}
                                    className="cursor-pointer mr-2"
                                    disabled={errorMessage !== undefined || isLoading}
                                />
                                {product.name}
                            </label>
                        )
                    })
                }
            </div>
            <div className='flex-row gap-2 mt-2'>
                <button className='p-2 rounded shadow-md bg-[var(--zanista-orange-mid)] hover:bg-[var(--zanista-orange)] cursor-pointer'
                    onClick={() => {
                        close();
                    }}
                >
                    Cancel
                </button>
                <button className={'p-2 rounded shadow-md bg-[var(--zanista-red-mid)] hover:bg-[var(--zanista-red)] cursor-pointer ' + (isLoading && "bg-gray-200")}
                     onClick={() => {
                        updateProductsAcceptingNewUsers();
                    }}
                    disabled={isLoading}
                >
                    Confirm
                </button>
            </div>
        </div>
    );
}

export default UserManagementPage;